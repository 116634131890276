import React, {Component} from 'react';
import {Field, FormSection} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import DateTimePicker from '../../../components/Input/DateTimePicker';
import TextArea from '../../../components/Input/TextArea';
import TextField from '../../../components/Input/TextField';
import TeamSelect from '../../../containers/team/TeamSelect';
import MenuItem from '@material-ui/core/MenuItem';

class TaskForm extends Component {
  render() {
    const {members, isAdmin, onChangeTeam} = this.props;
    return (
      <FormSection name="task">
        <Grid container spacing={24}>
          {
            isAdmin && (
              <Grid item xs={12}>
                <Field
                  name="team"
                  label="Команда"
                  component={TeamSelect}
                  fullWidth
                  margin="none"
                  onChange={e => {
                    onChangeTeam && onChangeTeam(e.target.value);
                  }}
                />
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Field
              name="assignee"
              label="Ответственный"
              component={TextField}
              select
              fullWidth
              margin="none"
            >
              {
                members.map(({id, name}) => (
                  <MenuItem key={id} value={id}>{name}</MenuItem>
                ))
              }
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="title"
              label="Название"
              required
              component={TextField}
              fullWidth
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="deadline"
              label="Крайний срок"
              required
              format={null}
              component={props => (
                <DateTimePicker
                  ampm={false}
                  format="dd.MM.yyyy HH:mm"
                  {...props}
                />
              )}
              fullWidth
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="description"
              label="Описание"
              component={TextField}
              InputProps={{
                inputComponent: TextArea,
                inputProps: {
                  rows: 2,
                  rowsMax: 5,
                },
              }}
              multiline
              fullWidth
              margin="none"
            />
          </Grid>
        </Grid>
      </FormSection>
    );
  }
}

export default TaskForm;
