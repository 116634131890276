import axios from 'axios';
import {set} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError, formValueSelector, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';

import Form from './form';

class OfferEdit extends Component {
  componentDidMount() {
    if (this.props.open && !this.props.initialized) {
      if (this.props.offer) {
        const offer = this.props.offer;

        if (offer.property.type.attributes) {
          const attributes = offer.property.type.attributes[offer.property.type.value];
          if (Array.isArray(attributes)) {
            offer.property.type.attributes[offer.property.type.value] = {};
          }
        }
        this.props.initialize({offer});
      } else {
        const defaultProperty = {
          address: {},
          type: {},
          additionalTypes: [],
          purposes: [],
          area: {unit: 'm2'},
          photos: [],
          layouts: [],
          watermark: false,
        };
        this.props.initialize({
          offer: {
            status: 'opened',
            property: this.props.property || defaultProperty,
            price: {value: {currency: 'RUB'}},
            ignoreExports: ['avito', 'cian'],
          },
        });
      }
    }
  }

  onTypeChange = e => {
    const selfUserTeam = this.props.selfUserTeams[0];
    this.props.change('offer.price.type', e === 'sell' ? 'sell' : 'rent_per_month');
    this.props.change('offer.revenue', e === 'sell' ? selfUserTeam.avgCommissionSell : selfUserTeam.avgCommissionRent);
  };

  onStatusChange = value => {
    this.props.change('offer.status', value);
  };

  render() {
    const {
      address,
      array,
      change,
      classes,
      handleSubmit,
      initialized,
      propertyArea,
      propertyType,
      offerType,
      offerStatus,
      pristine,
      submitting,
      onClose,
      open,
      exportSiteCityId,
    } = this.props;

    if (!open || !initialized) {
      return null;
    }

    return (
      <Dialog fullScreen open={open} classes={{paper: classes.dialog}}>
        <DialogTitle>
          Листинг
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close/>
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <form>
            <Form
              address={address}
              propertyArea={propertyArea}
              propertyType={propertyType}
              offerType={offerType}
              offerStatus={offerStatus}
              exportSiteCityId={exportSiteCityId}
              change={change}
              array={array}
              onTypeChange={e => this.onTypeChange(e)}
              onStatusChange={value => this.onStatusChange(value)}
            />
          </form>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button type="button" disabled={pristine || submitting} onClick={handleSubmit(this.submit)}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = values => {
    const {offer, onSubmitted, property} = this.props;
    let url = '/api/offers';

    if (offer) {
      url = `/api/offers/${offer.id}`;
    } else if (property) {
      url = `/api/properties/${property.id}/offers`;
    }

    return axios.post(url, {
      id: offer ? offer.id : null,
      offer: values.offer,
    })
      .then(resp => {
        values.offer.property.layouts = resp.data.layouts;
        values.offer.property.photos = resp.data.photos;
        values.offer.property.overallVideo = resp.data.overallVideo;
        values.offer.property.trafficVideo = resp.data.trafficVideo;
        values.offer.property.ruTubeVideo = resp.data.ruTubeVideo;
        values.offer.archiveReason = resp.data.archiveReason;
        onSubmitted({...values.offer, id: resp.data.id});
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(errors, propertyPath, message);
          }, {}));
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

OfferEdit = withStyles(styles)(OfferEdit);

const selector = formValueSelector('offer');
OfferEdit = connect(
  state => ({
    address: selector(state, 'offer.property.address'),
    offerType: selector(state, 'offer.type'),
    propertyArea: selector(state, 'offer.property.area'),
    propertyType: selector(state, 'offer.property.type.value'),
    offerStatus: selector(state, 'offer.status'),
    exportSiteCityId: selector(state, 'offer.exportSiteCityId'),
    selfUserTeams: state.root.selfUser.teams,
    offerExportsDisabled: state.root.classification.offerExportsDisabled,
  })
)(OfferEdit);

export default reduxForm({
  form: 'offer',
})(OfferEdit);
