import React, {Component} from 'react';
import {connect} from 'react-redux';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';
import {isEmpty} from 'lodash';

import {formatDateWithoutTime, mapLabels} from '../../../utils';
import base64url from '../../../utils/base64url';
import {queryFavoritesList} from '../../../queries/FavoritesQuery';
import {formatDate} from '../../../utils';
import RemoveFavorite from '../components/RemoveFavorite';
import EditNote from '../components/EditNote';
import FilterFavorites from '../components/FilterFavorites';
import AccessComponent from '../../../components/AccessComponent';
import MobilePaginationToolbar from '../../../components/MobilePaginationToolbar';
import TableLink from '../../../components/TableLink';

class FavoritesList extends Component {
  state = {
    loading: false,
    error: false,
    query: {},
    favorites: [],
    totalCount: 0,
    removeFavorite: null,
    openEdit: null,
    searchInput: '',
    openFilterDialog: false,
  };

  componentDidMount() {
    this.search(this.props.location);
    let query = this.props.location.search.substr(1);
    if (query) {
      const data = JSON.parse(base64url.decode(query));
      if (data.filter) {
        this.setState({searchInput: data.filter.searchQuery});
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.search(nextProps.location);
    }
  }

  search(location) {
    let query = {};
    const qs = location.search.substr(1);
    if (qs.length > 0) {
      try {
        query = JSON.parse(base64url.decode(qs));
      } catch (_) {
      }
    }

    this.setState({loading: true, query});
    queryFavoritesList(query)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            favorites: resp.data.data.favoritesList.items,
            totalCount: resp.data.data.favoritesList.totalCount,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  handleChangeSource = e => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.state.query, filter: {...this.state.query.filter, source: e.target.value}, offset: 0}));
    this.props.history.push(path);
  };

  handleChangePage = (_, page) => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.state.query, offset: page * 30, limit: 30}));
    this.props.history.push(path);
  };

  handleSearchChange = e => {
    this.setState({searchInput: e.target.value});
  };

  handleSearchInit = () => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.state.query, filter: {...this.state.query.filter, searchQuery: this.state.searchInput}, offset: 0}));
    this.props.history.push(path);
  };

  handleFilter = filter => {
    this.setState({openFilterDialog: false});
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({source: this.state.query.source, filter}));
    this.props.history.push(path);
    this.setState({searchInput: filter.searchQuery});
  };

  handleReset = () => {
    if(this.state.query.sourse) {
      const path = this.props.match.url + '?'
        + base64url.encode(JSON.stringify({source: this.state.query.source}));
      this.props.history.push(path);
    } else {
      this.props.history.push(this.props.match.url);
    }
    this.setState({searchInput: ''});
  };

  onRemoveFavorite = id => {
    const newFavorites = this.state.favorites.filter(data => data.id !== id);
    this.setState({favorites: newFavorites, removeFavorite: null, totalCount: this.state.totalCount-1});
  };

  onEditNote = (id, nextNote) => {
    let newFavorites = this.state.favorites.map(data => data.id === id ? {...data, note: nextNote} : data);
    this.setState({openEdit: false, favorites: newFavorites, note: nextNote});
  };

  render() {
    const {
      loading,
      error,
      favorites,
      totalCount,
      removeFavorite,
      query,
      openEdit,
      note,
      searchInput,
      openFilterDialog,
    } = this.state;
    const {
      classes,
      selfUser,
      history,
      entityTypeLabels,
      propertyPurposeLabels,
      contractTypesLabels,
    } = this.props;

    const favoriteTitle = favorite => {
      switch (favorite.entity.__typename) {
        case 'Property':
          return `${favorite.entity.address.value}`;
        case 'Offer':
          return `${favorite.entity.property.address.value}`;
        case 'Contact':
          return `${favorite.entity.name}`;
        case 'Deal':
          return `${favorite.entity.contact.name}, ${propertyPurposeLabels[favorite.entity.propertyPurpose]}`;
        case 'Request':
          return `№ ${favorite.entity.no} от ${formatDateWithoutTime(favorite.entity.date)}`;
        case 'DealOffer':
          return `${favorite.entity.offer.property.address.value}`;
        case 'Contract':
          return `${favorite.entity.no}, ${contractTypesLabels[favorite.entity.type]}`;
        default: return null;
      }
    };

    const entityLink = favorite => {
      switch (favorite.entity.__typename) {
        case 'Property':
          return `/properties/${favorite.entity.id}`;
        case 'Offer':
          return `/offers/${favorite.entity.id}`;
        case 'Contact':
          return `/contacts/${favorite.entity.id}`;
        case 'Deal':
          return `/deals/${favorite.entity.id}`;
        case 'Request':
          return `/requests/${favorite.entity.id}`;
        case 'DealOffer':
          return `/dealOffers/${favorite.entity.id}`;
        case 'Contract':
          return `/contracts/${favorite.entity.id}`;
        default: return null;
      }
    };

    const sourceOwn = memberId => selfUser.id === memberId;

    return(
      <React.Fragment>
        <div className="row" key={0}>
          <div className="column" style={{background: colors.grey[200], flex: 2}}>
            <Toolbar>
              <AccessComponent manager>
                <Select
                  value={(query.filter && query.filter.source) || 'OWN'}
                  input={<Input/>}
                  onChange={this.handleChangeSource}
                  className={classes.sourceSelect}
                >
                  <MenuItem value="OWN">Мое избранное</MenuItem>
                  <MenuItem value="TEAM" divider>Избранное команды</MenuItem>
                  {selfUser.teamMembers.map((member, index) => (
                    <MenuItem key={index} value={member.id}>{member.name}</MenuItem>
                  ))}
                </Select>
              </AccessComponent>
              <div className={classes.flex}/>
              <Hidden xsDown>
                <InputBase
                  className={classes.input}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Hidden>
              {!isEmpty(query.filter) ? (
                <IconButton
                  disabled={loading}
                  onClick={this.handleReset}
                >
                  <icons.Clear/>
                </IconButton>
              ) : null}
              <IconButton
                className={classes.rightIcon}
                disabled={loading}
                color={!isEmpty(query.filter) ? 'primary' : 'default'}
                onClick={() => this.setState({openFilterDialog: true})}
              >
                <icons.FilterList/>
              </IconButton>
            </Toolbar>
            <Hidden smUp>
              <Toolbar>
                <InputBase
                  classes={{root: classes.searchInputRoot, input: classes.searchInput}}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  className={classes.rightIcon}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Toolbar>
            </Hidden>
            <Divider/>
            <div className="content">
              {loading ? (
                <CircularProgress size={50} thickness={4} className={classes.progress}/>
              ) : (
                error ? (
                  <icons.ErrorOutline className={classes.progress}/>
                ) : (
                  <React.Fragment>
                    <Hidden xsDown>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Название сущности</TableCell>
                            <TableCell>Заметка</TableCell>
                            <TableCell>Автор</TableCell>
                            <TableCell>Сущность</TableCell>
                            <TableCell>Дата изменения</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {favorites.map((favorite, i) => (
                            <TableLink
                              href={`${entityLink(favorite)}`}
                              key={i}
                              className={classes.tableRow}
                              hover
                              onClick={e => {
                                e.preventDefault();
                                history.push(`${entityLink(favorite)}`);}}
                            >
                              <TableCell>{favoriteTitle(favorite)}</TableCell>
                              <TableCell
                                className={classes.editCell}
                                onClick = {e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  this.setState({openEdit: {id: favorite.id, name: favoriteTitle(favorite), note: favorite.note, sourceOwn: sourceOwn(favorite.member.id)}});
                                }}
                              >
                                <div className={classes.flexWrapper}>
                                  <div className={classes.noteWrapper}>
                                    {favorite.note ? favorite.note : null}
                                  </div>
                                  <div className={classes.flex}/>
                                  {sourceOwn(favorite.member.id) ? <icons.Edit className={classes.editIcon}/> : favorite.note ? <icons.MoreHoriz className={classes.editIcon}/> : null}
                                </div>
                              </TableCell>
                              <TableCell>{favorite.member.name}</TableCell>
                              <TableCell>{entityTypeLabels[favorite.entity.__typename]}</TableCell>
                              <TableCell>
                                <div className={classes.flexWrapper}>
                                  {formatDate(favorite.updatedAt)}
                                  <div className={classes.flex}/>
                                  <IconButton
                                    onClick={e => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      this.setState({removeFavorite: {id: favorite.id}});
                                    }}
                                    disabled={!(sourceOwn(favorite.member.id))}
                                  >
                                    <icons.Star/>
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableLink>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              colSpan={7}
                              rowsPerPage={30}
                              rowsPerPageOptions={[30]}
                              count={totalCount}
                              page={query.offset ? Math.floor(query.offset / 30) : 0}
                              onChangePage={this.handleChangePage}
                              labelDisplayedRows={({from, to, count}) => `${from}-${to} из ${count}`}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Hidden>
                    <Hidden smUp>
                      {favorites.map((favorite, i) => (
                        <Paper
                          key={i}
                          elevation={0}
                          square
                          onClick={() => history.push(`${entityLink(favorite)}`)}
                        >
                          <CardContent style={{position: 'relative'}}>
                            <div className={classes.flexWrapper}>
                              <div className={classes.mobileTextWrap}>
                                <Typography>
                                  {entityTypeLabels[favorite.entity.__typename]}, {favoriteTitle(favorite)}
                                </Typography>
                                <Typography variant="subtitle1">
                                  {favorite.note}
                                </Typography>
                              </div>
                              <div className={classes.flex}/>
                              <IconButton
                                onClick={e => {
                                  e.stopPropagation();
                                  this.setState({removeFavorite: {id: favorite.id}});
                                }}
                                disabled={!(sourceOwn(favorite.member.id))}
                              >
                                <icons.Star/>
                              </IconButton>
                            </div>
                          </CardContent>
                          <Divider/>
                        </Paper>
                      ))}
                      <MobilePaginationToolbar
                        totalCount={totalCount}
                        page={query.offset ? Math.ceil(query.offset / 30) : 0}
                        handleOnChangePage={(e, page) => this.handleChangePage(e, page)}
                      />
                    </Hidden>
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </div>
        {removeFavorite? (
          <RemoveFavorite
            open={Boolean(removeFavorite)}
            initialValues={removeFavorite}
            onSubmitted={() => this.onRemoveFavorite(removeFavorite.id)}
            onClose={() => this.setState({removeFavorite: null})}
          />
        ) : null}
        {openEdit ? (
          <EditNote
            open={Boolean(openEdit)}
            favData={openEdit}
            sourceOwn={openEdit.sourceOwn}
            onSubmitted={newNote => this.onEditNote(openEdit.id, newNote, note)}
            initialValues={{
              note: openEdit.note,
            }}
            onClose={() => this.setState({openEdit: null})}
          />
        ) : null}
        {openFilterDialog ? (
          <FilterFavorites
            open={openFilterDialog}
            initialValues={{
              ...query.filter,
            }}
            onClose={() => this.setState({openFilterDialog: false})}
            onSubmit={this.handleFilter}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  flex: {
    flex: 1,
  },
  tableRow: {
    display:'table-row',
    color: 'inherit',
    height: 48,
    outline: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    cursor: 'default',
    '&:hover': {
      backgroundColor:'rgba(0,0,0,0.07)',
    },
  },
  table: {
    background: 'white',
    marginBottom: 56 + 48,
  },
  requirementsCell: {
    whiteSpace: 'normal',
    minWidth: 200,
  },
  requirementsWrapper: {
    overflow: 'hidden',
    maxHeight: 48,
    lineClamp: 3,
    fontSize: '0.75rem',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  leftIcon: {
    marginLeft: -12,
  },
  rightIcon: {
    marginRight: -12,
  },
  status: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 6,
    height: '100%',
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  malformedIcon: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginRight: 8,
    float: 'left',
    verticalAlign: 'middle',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  editIcon: {
    fontSize: 18,
    marginLeft: 8,
    color: colors.grey[600],
    verticalAlign: 'middle',
    visibility: 'hidden',
  },
  editCell: {
    cursor: 'pointer',
    whiteSpace: 'normal',
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
  noteWrapper: {
    overflow: 'hidden',
    maxHeight: 48,
    lineClamp: 3,
    fontSize: '0.75rem',
  },
  mobileTextWrap: {
    maxWidth: 'calc(100% - 48px)',
    overflow: 'hidden',
  },
  mobilePagination: {
    marginRight: 16,
  },
});

export default connect(
  state => ({
    selfUser: state.root.selfUser,
    entityTypeLabels: mapLabels(state.root.classification.entityTypes, 'value', 'label'),
    propertyPurposeLabels: mapLabels(state.root.classification.propertyPurposes, 'value', 'label'),
    contractTypesLabels: mapLabels(state.root.classification.contractTypes, 'value', 'label'),
  }),
)(withStyles(styles)(FavoritesList));
