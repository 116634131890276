import {colors, withStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import React, {Component} from 'react';
import {Field, reduxForm, reset, SubmissionError} from 'redux-form';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as icons from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import TextField from '../../../components/Input/TextField';
import DateTimePicker from '../../../components/Input/DateTimePicker';
import {formatDate, showResponseError} from '../../../utils';
import {set} from 'lodash';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';

class Reminders extends Component {
  state = {
    formCreate: false,
    openMenu: false,
    datetime: null,
  };

  render() {
    const {
      classes,
      handleSubmit,
      submitting,
      task,
      change,
    } = this.props;
    const {
      datetime,
      formCreate,
      openMenu,
    } = this.state;

    return (
      <>
        {task.reminders ? (
          <List subheader={
            <ListSubheader
              disableSticky
              style={{position: 'relative', padding: '0 24px'}}
            >
              Напоминания
            </ListSubheader>
          }>
            {task.reminders.map((reminder, index) => (
              <ListItem
                key={index}
                className={classes.listItem}
              >
                <ListItemText
                  primary={formatDate(reminder.datetime, 'EEEEEE, dd MMM yyyy, HH:mm')}
                  secondary={reminder.text}
                />
                <icons.Delete
                  className={classes.deleteIcon}
                  onClick={e => this.setState({
                    openMenu: {
                      target: e.currentTarget,
                      id: reminder.id,
                    },
                  })}
                />
              </ListItem>
            ))}
          </List>
        ) : null}
        {formCreate ? (
          <div className={`${classes.remindersWrap} ${classes.remindersForm}`}>
            <Typography variant="caption">Добавить напоминание</Typography>
            <React.Fragment>
              <Field
                component={props => (
                  <DateTimePicker
                    ampm={false}
                    format="dd.MM.yyyy HH:mm"
                    placeholder={'Укажите дату и время напоминания'}
                    required
                    fullWidth
                    margin="none"
                    value={datetime}
                    onChange={value => {
                      this.setState({datetime: value});
                      change('datetime', value);
                    }}
                    {...props}
                  />
                )}
                name="datetime"
                label="Дата и время"
                fullWidth
                margin="normal"
              />
              <Field
                component={TextField}
                name="text"
                label="Описание"
                multiline
                fullWidth
                margin="normal"
              />
              <Button
                type="button"
                color="primary"
                size="small"
                variant="contained"
                disabled={submitting}
                onClick={() => this.setState({formCreate: false})}
                className={classes.cancelButton}
              >
                Отмена
              </Button>
              <Button
                type="button"
                color="primary"
                size="small"
                variant="contained"
                disabled={submitting}
                onClick={handleSubmit(this.submit)}
              >
                Добавить
              </Button>
            </React.Fragment>
          </div>
        ) : (
          <div className={classes.remindersWrap}>
            <Button
              type="button"
              color="primary"
              disabled={submitting}
              onClick={() => this.setState({formCreate: true})}
              size="small"
              variant="contained"
            >
              <icons.AddOutlined/>
              напоминание
            </Button>
          </div>
        )}
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => {
              this.removeDocument();
            }}>
              Удалить напоминание
            </MenuItem>
          </Menu>
        ) : null}
      </>
    );
  }

  submit = data => {
    return this.props.onCreate(data)
      .then(() => this.setState({formCreate: false, datetime: null}))
      .catch(err => {
        showResponseError(err.response, SubmissionError, set);
        throw err;
      });
  };

  removeDocument = () => {
    const {openMenu} = this.state;
    this.props.onRemove(openMenu.id)
      .then(() => this.setState({openMenu: false}));
  };
}

const styles = theme => ({
  remindersWrap: {
    padding: '0 24px 24px',
  },
  cancelButton: {
    marginRight: theme.spacing.unit,
  },
  deleteIcon: {
    fontSize: 18,
    color: colors.grey[600],
    marginLeft: 'auto',
    '&:hover': {
      color: colors.red[800],
      cursor: 'pointer',
    },
  },
  remindersForm: {
    border: '1px dashed #cecece',
    padding: 20,
    margin: '10px 20px 40px',
    borderRadius: 10,
  },
  listItem: {
    '& p': {
      whiteSpace: 'pre-wrap',
    },
  },
});

const afterSubmit = (result, dispatch) => dispatch(reset('create_task_reminder'));

export default reduxForm({
  form: 'create_task_reminder',
  enableReinitialize: true,
  onSubmitSuccess: afterSubmit,
})(withStyles(styles)(Reminders));
