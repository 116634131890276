import axios from 'axios';
import {set} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, SubmissionError} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import TaskForm from '../components/TaskForm';
import {hasRole} from '../../../utils/roleFunc';
import * as userRoles from '../../../constants/userRoles';

class TaskAdd extends Component {
  state = {
    isAdmin: false,
    members: [],
  };

  componentDidMount() {
    const {initialized, initialize, selfUser} = this.props;
    if (!initialized && selfUser) {
      const isAdmin = hasRole(selfUser.role, userRoles.ADMIN);

      this.setState(
        {
          isAdmin,
          members: selfUser.teamMembers,
        },
        function () {
          initialize({
            task: {
              deadline: null,
              assignee: selfUser.id,
              team: selfUser.team ? selfUser.team : null,
            },
          });
        });
    }
  }

  loadMembers = teamId => {
    const {change} = this.props;

    axios
      .get(`/api/teams/${teamId}/members?short`)
      .then(resp => {
        if (resp && resp.data && resp.data.items) {
          this.setState({members: resp.data.items}, function () {
            if (resp.data.items.length > 0) {
              change('task.assignee', resp.data.items[0].id);
            } else {
              change('task.assignee', null);
            }
          });
        }
      })
      .catch(() => {
        this.setState({members: []}, function () {
          change('task.assignee', null);
        });
      });
  };

  render() {
    const {
      classes,
      handleSubmit,
      initialized,
      open,
      pristine,
      submitting,
      onClose,
    } = this.props;

    const {isAdmin, members} = this.state;

    if (!initialized) {
      return null;
    }

    return (
      <Dialog open={open} maxWidth="sm" classes={{paper: classes.dialog}}>
        <DialogTitle>
          Новая задача
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close/>
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent className={classes.dialogContent}>
          <TaskForm
            isAdmin={isAdmin}
            members={members}
            onChangeTeam={this.loadMembers}
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button type="button" disabled={pristine || submitting} onClick={handleSubmit(this.submit)}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {onSubmitted, entity} = this.props;

    return axios.post('/api/v1/createTask', {
      ...data.task,
      entity,
    })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(errors, 'task.' + propertyPath, message);
          }, {}));
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      })
      .then(resp => onSubmitted({...data.task, id: resp.data.id}));
  };
}

const styles = () => ({
  dialogContent: {
    paddingTop: 24,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

TaskAdd = reduxForm({
  form: 'add_task',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(TaskAdd);

export default connect(
  state => ({
    selfUser: state.root.selfUser,
    teamMembers: state.root.selfUser.teamMembers,
    initialValues: {
      task: {
        deadline: null,
        assignee: state.root.selfUser ? state.root.selfUser.id : null,
      },
    },
  }),
)(withStyles(styles)(TaskAdd));
