import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography/Typography';
import AccessComponent from '../../components/AccessComponent';
import {isMobileWindowWidth} from '../../utils';

class PropertyDetails extends Component {
  state = {
    telegramAuthProcessing: false,
  };

  openTelegramAuthWindow(url) {
    if (isMobileWindowWidth()) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  }

  telegramAuthEnable = () => {
    const {onTelegramAuthError} = this.props;
    this.setState({telegramAuthProcessing: true});
    axios.post('/api/users/self/telegramAuthEnable')
      .then(res => {
        if (res && res.data && res.data.redirect) {
          this.openTelegramAuthWindow(res.data.redirect);
        }
      })
      .catch(error => {
        if (error.response && error.response.data.message) {
          onTelegramAuthError(error.response.data.message);
        } else {
          onTelegramAuthError('Произошла неизвестная ошибка');
        }
      })
      .finally(() => {
        this.setState({telegramAuthProcessing: false});
      });
  };

  telegramAuthDisable = () => {
    const {onTelegramAuthChanged, onTelegramAuthError} = this.props;
    this.setState({telegramAuthProcessing: true});
    axios.delete('/api/users/self/telegramAuthDisable')
      .then(res => {
        if (res && res.data && res.data.success) {
          onTelegramAuthChanged(false);
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          onTelegramAuthError(error.response.data.message);
        } else {
          onTelegramAuthError('Произошла неизвестная ошибка');
        }
      })
      .finally(() => {
        this.setState({telegramAuthProcessing: false});
      });
  };

  render() {
    const {
      member,
      classes,
      userRoles,
      selfUser,
      telegramAuthError,
    } = this.props;
    const {telegramAuthProcessing} = this.state;

    let access = !!(this.props.selfUser.teamMembers.find(item => item.id === member.id));
    const selfPersonData = selfUser && member && selfUser.id === member.id;

    return (
      <>
        <div className={classes.container}>
          <List>
            {member.email ? (
              <ListItem dense>
                <ListItemText primary={member.email} secondary="Email"/>
              </ListItem>
            ) : null}
            {member.phone ? (
              <ListItem dense>
                <ListItemText primary={member.phone} secondary="Телефон"/>
              </ListItem>
            ) : null}
            <AccessComponent admin condition={access}>
              {member.commission ? (
                <ListItem dense>
                  <ListItemText primary={member.commission} secondary="Комиссия, %"/>
                </ListItem>
              ) : null}
            </AccessComponent>
            {member.role ? (
              <ListItem dense>
                <ListItemText primary={userRoles[member.role] || 'Администратор'} secondary="Роль"/>
              </ListItem>
            ) : null}
            {selfPersonData ? (
              <ListItem dense>
                <Typography color="textSecondary" component="a" href="/changePassword">Сменить пароль</Typography>
              </ListItem>
            ) : null}
          </List>
        </div>
        {selfPersonData ? (
          <div className={[classes.telegramAuthTitle, classes.container].join(' ')}>
            <List>
              <ListItem dense>
                <Typography className={classes.title}>Внешние сервисы авторизации</Typography>
              </ListItem>
              <ListItem dense>
                <ListItemText>
                  <Typography className={classes.telegramAuthLogo}>
                    <img src="/assets/social/telegram.png" alt="Telegram" title="Telegram"/>&nbsp;&nbsp;Telegram
                  </Typography>
                </ListItemText>
                {
                  member.telegramAuth ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      className={classes.integrationBtn}
                      disabled={!!telegramAuthProcessing}
                      onClick={() => this.telegramAuthDisable()}
                    >
                      Отключить
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.integrationBtn}
                      disabled={!!telegramAuthProcessing}
                      onClick={() => this.telegramAuthEnable()}
                    >
                      Подключить
                    </Button>
                  )
                }
              </ListItem>
              {
                telegramAuthError ? (
                  <ListItem>
                    <Typography className={classes.telegramAuthError}>
                      {telegramAuthError}
                    </Typography>
                  </ListItem>
                ) : null
              }
            </List>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = theme => ({
  avatar: {
    background: colors.grey[300],
  },
  photos: theme.mixins.gutters({
    paddingTop: 8,
    paddingBottom: 8,
  }),
  headerPopoverPaper: {
    padding: 16,
    width: '100%',
  },
  container: {
    background: 'white',
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.grey[300],
  },
  telegramAuthTitle: {
    marginTop: 20,
    marginBottom: 10,
  },
  telegramAuthError: {
    marginTop: 20,
    color: colors.red[500],
  },
  telegramAuthLogo: {
    height: 24,
    lineHeight: '24px',
    verticalAlign: 'middle',
    display: 'flex',
  },
});

export default connect(
  ({root}) => ({
    selfUser: root.selfUser,
    userRoles: root.classification.userRoles.reduce((prev, cur) => ({
      ...prev,
      [cur.value]: cur.label,
    }), {}),
  }),
  {}
)(withStyles(styles)(PropertyDetails));
