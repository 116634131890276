import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as icons from '@material-ui/icons';
import RequestListItem from '../../../components/relations/RequestListItem';
import ShortPagination from '../../../components/ShortPagination';

class Requests extends Component {
  state = {
    loading: false,
    error: null,
    requests: [],
  };

  componentDidMount() {
    if (this.props.requests) {
      this.setState({requests: this.props.requests});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.requests !== this.props.requests) {
      this.setState({requests: nextProps.requests});
    }
  }

  handleClickAdd = () => {
    const {history} = this.props;
    history.push('/requests/');
  };

  render() {
    const {requests, error, loading} = this.state;
    const {
      handleDialogOpen,
      handleChangePage,
      page,
      perPage,
      totalCount,
    } = this.props;

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Листинг в заявках (beta)
          <ListItemSecondaryAction>
            {loading ? (
              <IconButton disabled>
                <CircularProgress size={24}/>
              </IconButton>
            ) : (
              error ? (
                <IconButton disabled>
                  <icons.ErrorOutline/>
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => this.handleClickAdd()}
                >
                  <icons.Add/>
                </IconButton>
              )
            )}
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {requests.map(request => (
          <RequestListItem
            key={request.id}
            data={request}
            handleDialogOpen={() => handleDialogOpen(request)}
          />
        ))}
        <ShortPagination
          page={page}
          totalCount={totalCount}
          perPage={perPage}
          handleChangePage={handleChangePage}
        />
      </List>
    );
  }
}

export default Requests;
