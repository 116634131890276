import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DateTimePicker from 'material-ui-pickers/DateTimePicker';
import React from 'react';
import isEqualDate from 'date-fns/isEqual';
import formatDate from '../../../utils/formatDate';

class EditableDeadline extends React.Component {
  state = {
    editing: false,
    saving: false,
    value: this.props.deadline,
  };

  handleClick = () => {
    const {editable} = this.props;
    if (editable) {
      this.setState({editing: true});
    }
  };

  render() {
    const {classes, deadline, editable, onSave, expired} = this.props;
    const {editing, saving, value} = this.state;

    if (!editing || !editable) {
      return (
        <ListItem
          className={classes.listItem}
          onClick={this.handleClick}
        >
          <ListItemText
            primary={formatDate(deadline, 'EEEEEE, dd MMM yyyy, HH:mm')}
            secondary="Крайний срок"
            secondaryTypographyProps={{color: expired ? 'error' : 'textSecondary'}}
          />
          {editable ? <icons.Edit className={classes.editIcon}/> : null}
        </ListItem>
      );
    }

    const pristine = isEqualDate(deadline, value);

    return (
      <div style={{padding: '0 24px'}}>
        <DateTimePicker
          ampm={false}
          format="dd.MM.yyyy HH:mm"
          required
          fullWidth
          margin="none"
          value={value}
          onChange={value => {
            this.setState({value: value});
          }}
        />
        <div style={{paddingTop: 8}}>
          <Button
            size="small"
            disabled={pristine || saving}
            onClick={() => {
              this.setState({saving: true});
              onSave(value).then(() => this.setState({
                editing: false,
                saving: false,
              }));
            }}
          >
            Сохранить
          </Button>
          <IconButton
            style={{width: 32, height: 32}}
            disabled={saving}
            onClick={() => {
              this.setState({
                value: deadline,
                editing: false,
              });
            }}
          >
            <icons.Close style={{width: 16, height: 16}}/>
          </IconButton>
        </div>
      </div>
    );
  }
}

const styles = () => ({
  editIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',
  },
  listItem: {
    cursor: 'pointer',
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
});

export default withStyles(styles)(EditableDeadline);
