import axios from 'axios';
import {colors, ListItem, ListItemText} from '@material-ui/core';
import List from '@material-ui/core/List';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import {withApollo} from 'react-apollo';
import gql from 'graphql-tag';
import {queryTaskDetails} from '../../../queries/TaskDetailsQuery';
import ActivityEventsList from '../../../components/ActivityEventsList';
import CommentArea from '../components/CommentArea';
import ControlButton from '../components/ControlButton';
import EditableAssignee from '../components/EditableAssignee';
import EditableDeadline from '../components/EditableDeadline';
import EditableDescription from '../components/EditableDescription';
import EditableTitle from '../components/EditableTitle';
import Members from '../components/Members';
import Documents from '../components/Documents';
import {formatDateWithoutTime, mapLabels} from '../../../utils';
import {hasRole} from '../../../utils/roleFunc';
import * as userRoles from '../../../constants/userRoles';
import Reminders from '../components/Reminders';

class TaskView extends Component {
  state = {
    loading: true,
    error: false,
    isAdmin: false,
    members: [],
  };

  componentDidMount() {
    const {selfUser} = this.props;
    if (selfUser.id) {
      this.onOpenTask();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selfUser !== this.props.selfUser) {
      this.onOpenTask();
    }
  }

  onOpenTask = () => {
    const {selfUser, id, match} = this.props;
    const isAdmin = hasRole(selfUser.role, userRoles.ADMIN);
    const fetchTask = this.loadTask;

    this.setState(
      {
        isAdmin,
        members: selfUser.teamMembers,
      },
      function() {
        fetchTask(id || match.params.id);
      }
    );
  };

  loadTask = taskId => {
    const {selfUser} = this.props;
    const {isAdmin} = this.state;
    this.setState({loading: true});

    queryTaskDetails(taskId)
      .then(resp => {
        const task = resp.data.data.task || null;
        if (!task) {
          this.setState({error: true});
          return;
        }

        this.setState({
          task,
          loading: false,
        });
        if (isAdmin && selfUser.team !== task.team.id) {
          this.loadMembers(task.team.id);
        }
      })
      .catch(() => {
        this.setState({error: true, loading: false});
      });
  };

  loadMembers = teamId => {
    axios
      .get(`/api/teams/${teamId}/members?short`)
      .then(resp => {
        if (resp && resp.data && resp.data.items) {
          this.setState({members: resp.data.items});
        }
      })
      .catch(() => {
        this.setState({members: []});
      });
  };

  updatedTask = (res, fields) => {
    if (res && res.data && res.data.task) {
      this.setState({task: {...this.state.task, ...res.data.task}});
    }
    if (fields) {
      this.setState({task: {...this.state.task, ...fields}});
    }
    this.activityEventsList.updateActivity();
  };

  updateDescription = description => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/updateTask', {
        taskId: id || match.params.id,
        description,
      })
      .then(() => {
        client.writeFragment({
          id: id || match.params.id,
          fragment: gql`
            fragment updatedDescriptionTask on Task {
              description
            }
          `,
          data: {
            description,
          },
        });
      })
      .then(() => this.updatedTask(null, {description}));
  };

  updateTitle = title => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/updateTask', {
        taskId: id || match.params.id,
        title,
      })
      .then(() => {
        client.writeFragment({
          id: id || match.params.id,
          fragment: gql`
            fragment updatedTitleTask on Task {
              title
            }
          `,
          data: {
            title,
          },
        });
      })
      .then(() => this.updatedTask(null, {title}));
  };

  reassign = assigneeId => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/reassignTask', {
        taskId: id || match.params.id,
        assigneeId,
      })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                assignee {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(this.updatedTask);
  };

  addCoworker = coworkerId => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/addCoworkerToTask', {
        taskId: id || match.params.id,
        coworkerId,
      })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                coworkers {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(this.updatedTask);
  };

  removeCoworker = coworkerId => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/removeCoworkerFromTask', {
        taskId: id || match.params.id,
        coworkerId,
      })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                coworkers {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(this.updatedTask);
  };

  addObserver = observerId => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/addObserverToTask', {
        taskId: id || match.params.id,
        observerId,
      })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                observers {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(this.updatedTask);
  };

  removeObserver = observerId => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/removeObserverFromTask', {
        taskId: id || match.params.id,
        observerId,
      })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                observers {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(this.updatedTask);
  };

  addDocument = data => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/addDocumentToTask', {
        taskId: id || match.params.id,
        document: data.document,
      })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                documents {
                  fileName
                  fileSize
                  mimeType
                  name
                  path
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(this.updatedTask);
  };

  removeDocument = path => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/removeDocumentFromTask', {
        taskId: id || match.params.id,
        path: path,
      })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                documents {
                  fileName
                  fileSize
                  mimeType
                  name
                  path
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(this.updatedTask);
  };

  createReminder = data => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/createReminderInTask', {
        taskId: id || match.params.id,
        datetime: data.datetime,
        text: data.text,
      })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                reminders {
                  id
                  datetime
                  text
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(this.updatedTask);
  };

  removeReminder = reminderId => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/removeReminderFromTask', {
        taskId: id || match.params.id,
        reminderId,
      })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                reminders {
                  id
                  datetime
                  text
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(this.updatedTask);
  };

  reschedule = deadline => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/rescheduleTask', {
        taskId: id || match.params.id,
        deadline,
      })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                deadline
                expired
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(this.updatedTask);
  };

  close = () => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/closeTask', {
        taskId: id || match.params.id,
      })
      .then(() => {
        client.writeFragment({
          id: id || match.params.id,
          fragment: gql`
            fragment closedTask on Task {
              closed
            }
          `,
          data: {
            closed: true,
          },
        });
      })
      .then(() => this.updatedTask(null, {closed: true}));
  };

  addComment = comment => {
    const {match, id} = this.props;

    return axios
      .post('/api/v1/addCommentToTask', {
        taskId: id || match.params.id,
        comment,
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  reopen = () => {
    const {client, match, id} = this.props;

    return axios
      .post('/api/v1/reopenTask', {
        taskId: id || match.params.id,
      })
      .then(() => {
        client.writeFragment({
          id: match.params.id,
          fragment: gql`
            fragment reopenedTask on Task {
              closed
            }
          `,
          data: {
            closed: false,
          },
        });
      })
      .then(() => this.updatedTask(null, {closed: false}));
  };

  render() {
    const {
      classes,
      match,
      propertyTypes,
      contractTypes,
      selfUser,
      id,
      handleClose,
    } = this.props;
    const {loading, error, members, task} = this.state;

    if (error) {
      return (
        <Dialog
          open={true}
          maxWidth="sm"
          fullWidth
          classes={{paper: classes.dialog}}
          onClick={() => (window.location.href = window.location.pathname)}
        >
          <icons.ErrorOutline className={classes.errorIcon} />
        </Dialog>
      );
    }

    if (loading || !task) {
      return null;
    }

    return (
      <Dialog
        open={true}
        maxWidth="sm"
        fullWidth
        classes={{paper: classes.dialog}}
      >
        <DialogTitle className={classes.dialogTitle}>
          <EditableTitle
            title={task.title}
            onSave={value => this.updateTitle(value)}
            editable={task.permissions.canUpdateTask}
          />
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              handleClose && handleClose();
              window.location.href = '#/';
            }}
          >
            <icons.Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent style={{padding: 0}}>
          <List>
            <EditableDeadline
              deadline={task.deadline}
              expired={task.expired}
              onSave={value => this.reschedule(value)}
              editable={task.permissions.canRescheduleTask}
            />
            <EditableDescription
              description={task.description ? task.description : ''}
              onSave={value => this.updateDescription(value)}
              editable={task.permissions.canUpdateTask}
            />
            <EditableAssignee
              assignee={task.assignee}
              members={members}
              onSave={value => this.reassign(value)}
              editable={task.permissions.canReassignTask}
            />
            {task.assignee.id !== task.author.id ? (
              <ListItem>
                <ListItemText
                  primary={task.author.name}
                  secondary="Постановщик"
                />
              </ListItem>
            ) : null}
            <Members
              label="Соисполнители"
              members={task.coworkers}
              teamMembers={members}
              onAdd={this.addCoworker}
              onRemove={this.removeCoworker}
              editable={
                task.permissions.canAddCoworkerToTask &&
                task.permissions.canRemoveCoworkerFromTask
              }
            />
            <Members
              label="Наблюдатели"
              members={task.observers}
              teamMembers={members}
              onAdd={this.addObserver}
              onRemove={this.removeObserver}
              editable={
                (task.permissions.canAddObserverToTask &&
                  task.permissions.canRemoveObserverFromTask) ||
                (hasRole(selfUser.role, userRoles.MANAGER) ||
                  hasRole(selfUser.role, userRoles.ADMIN))
              }
            />
          </List>
          <div style={{padding: '0 24px 16px 24px'}}>
            {task.entities.map(entity => {
              let href;
              let label;
              let title;
              switch (entity.__typename) {
                case 'Contact':
                  href = `/contacts/${entity.id}`;
                  label = `Контакт ${entity.name}`;
                  title = label;
                  break;
                case 'Deal':
                  href = `/deals/${entity.id}`;
                  label = `Заявка ${propertyTypes[entity.propertyType]}`;
                  title = label;
                  break;
                case 'Request':
                  href = `/requests/${entity.id}`;
                  label = `Заявка № ${entity.no} от ${formatDateWithoutTime(entity.date)}`;
                  title = label;
                  break;
                case 'Offer':
                  href = `/offers/${entity.id}`;
                  label = `Листинг ${entity.name}`;
                  title = label;
                  break;
                case 'Property':
                  href = `/properties/${entity.id}`;
                  label = 'Объект';
                  title = entity.address.value;
                  break;
                case 'DealOffer':
                  href = `/dealOffers/${entity.id}`;
                  label = 'Сделка';
                  title = entity.offer.property.address.value;
                  break;
                case 'Contract':
                  href = `/contracts/${entity.id}`;
                  label = `Договор ${entity.no}, ${contractTypes[entity.type]}`;
                  title = label;
                  break;
                default:
                  return null;
              }
              return (
                <Chip
                  key={entity.id}
                  component="a"
                  target="_blank"
                  href={href}
                  style={{cursor: 'pointer', textDecoration: 'none'}}
                  label={label}
                  title={title}
                />
              );
            })}
          </div>
          <ControlButton
            closed={task.closed}
            onClose={() => this.close()}
            onReopen={() => this.reopen()}
            permissions={task.permissions}
          />
          <CommentArea
            onSave={value => this.addComment(value)}
            permissions={task.permissions}
          />
          <Documents
            task={task}
            onAddDocument={data => this.addDocument(data)}
            onRemoveDocument={data => this.removeDocument(data)}
          />
          <Reminders
            task={task}
            onCreate={data => this.createReminder(data)}
            onRemove={id => this.removeReminder(id)}
          />
          <ActivityEventsList
            entityId={id || match.params.id}
            onRef={ref => (this.activityEventsList = ref)}
            aggregateType="task"
          />
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = () => ({
  dialogTitle: {
    paddingRight: 64,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  actionButtonsWrapper: {
    padding: '0 18px',
    display: 'flex',
    flexDirection: 'row',
  },
  actionButton: {
    margin: 6,
  },
  commentTextFieldWrapper: {
    padding: '0 24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  saveIconButton: {
    marginBottom: -8,
    marginRight: -12,
    marginLeft: 12,
  },
  authorLink: {
    marginLeft: 6,
  },
  errorIcon: {
    color: colors.grey[400],
    width: 64,
    height: 64,
    margin: 'auto',
    padding: '5rem',
  },
});

export default connect(state => ({
  selfUser: state.root.selfUser,
  propertyTypes: mapLabels(
    state.root.classification.propertyTypes,
    'value',
    'label'
  ),
  contractTypes: mapLabels(
    state.root.classification.contractTypes,
    'value',
    'label'
  ),
}))(withApollo(withStyles(styles)(TaskView)));
