import {executeQuery} from '../utils';
import gql from 'graphql-tag';

export const queryFavoritesList = query => {
  return executeQuery(
    `query favoritesList ($filter: FavoritesFilter, $offset: Int, $limit: Int) {
      favoritesList(filter: $filter, offset: $offset, limit: $limit) {
        items {
          id
          entity {
           __typename
            ... on Property{
              id
              address {
                value
              }
            }
            ... on Offer{
              id
              property {
                address {
                  value
                }
              }
            }
            ... on Contact{
              id
              name
            }
            ... on Deal {
              id
              contact {
                name
              }
              propertyPurpose
            }
            ... on Request {
              id
              no
              date
            }
            ... on Contract {
              id
              no
              type
            }
            ... on DealOffer {
              id
              offer {
                property {
                  address {
                    value
                  }
                }
              }
              deal {
                contact {
                  name
                }
              }
            }
          }
          member {
            id
            name
          }
          note
          updatedAt
        }
        totalCount
      }
    }`,
    {...query}
  );
};

export const queryInFavorites = query => {
  return executeQuery(
    `query inFavorites($ids: [ID!]!) {
      inFavorites(ids: $ids){
        items {
          note
          id
          entity {
            __typename
            ... on Property{
              id
            }
            ... on Offer{
              id
            }
            ... on Contact{
              id
            }
            ... on Deal {
              id
            }
            ... on Request {
              id
            }
            ... on DealOffer {
              id
            }
            ... on Contract {
              id
            }
          }
        }
      }
    }`,
    {...query}
  );
};

export const inFavoritesQuery = gql`
  query inFavorites($ids: [ID!]!) {
    inFavorites(ids: $ids) {
      items {
        note
        id
        entity {
          __typename
          ... on Property {
            id
          }
          ... on Offer {
            id
          }
          ... on Contact {
            id
          }
          ... on Deal {
            id
          }
          ... on Request {
            id
          }
          ... on DealOffer {
            id
          }
          ... on Contract {
            id
          }
        }
      }
    }
  }
`;
