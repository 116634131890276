import axios from 'axios';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from 'react';
import {Query, withApollo} from 'react-apollo';
import {withRouter} from 'react-router-dom';
import gql from 'graphql-tag';
import IconButton from '@material-ui/core/IconButton';
import TaskListQuery from '../../../queries/TaskListQuery';
import formatDate from '../../../utils/formatDate';
import AccessComponent from '../../../components/AccessComponent';
import {hasRole} from '../../../utils/roleFunc';
import * as userRoles from '../../../constants/userRoles';
import TaskAdd from './TaskAdd';
import {connect} from 'react-redux';

class EntityTaskList extends Component {
  state = {
    openAdd: false,
    showClosed: false,
    tasksSource: 'OWN',
  };

  addTask = (newTask, refetch) => {
    const {match} = this.props;

    window.location.href = `${match.url}#/tasks/${newTask.id}`;

    refetch();
  };

  changetasksSource = () => {
    this.setState({tasksSource: this.state.tasksSource === 'OWN' ? 'TEAM' : 'OWN'});
  };

  closeTask = id => {
    const {client} = this.props;

    axios.post('/api/v1/closeTask', {taskId: id}).then(() => {
      client.writeFragment({
        id,
        fragment: gql`
          fragment closedTask on Task {
            closed
          }
        `,
        data: {
          closed: true,
        },
      });
    });
  };

  reopenTask = id => {
    const {client} = this.props;

    axios.post('/api/v1/reopenTask', {taskId: id}).then(() => {
      client.writeFragment({
        id,
        fragment: gql`
          fragment reopenedTask on Task {
            closed
          }
        `,
        data: {
          closed: false,
        },
      });
    });
  };

  render() {
    const {
      classes,
      entity,
      match,
      selfUser,
      isFavorite,
      toggleFavorite,
      addNote,
      note,
    } = this.props;
    const {
      openAdd,
      showClosed,
      tasksSource,
    } = this.state;

    const addButton = (
      <Button
        variant="text"
        size="small"
        className={classes.addButton}
        color="primary"
        onClick={() => this.setState({openAdd: true})}
      >
        <icons.Add/>
        задача
      </Button>
    );

    const allTasksButton = (
      <Button
        variant="text"
        size="small"
        className={classes.addButton}
        color="primary"
        onClick={() => this.changetasksSource()}
      >
        {tasksSource === 'OWN' ? 'Все задачи' : 'Свои задачи'}
      </Button>
    );

    const addToFavorites = (
      <IconButton
        onClick={() => toggleFavorite(this.props.entity.id)}
        classes={{root: classes.dialogIcon}}
      >
        {isFavorite ? <icons.Star/> : <icons.StarBorder/>}
      </IconButton>
    );

    const addNoteBtn = (
      <Button
        variant="text"
        size="small"
        className={classes.addButton}
        color="primary"
        onClick={() => addNote()}
      >
        <icons.Add/>
        заметка
      </Button>
    );

    return (
      <div className={classes.root}>
        <Query
          query={TaskListQuery}
          variables={{filter: {entityId: entity.id, source: tasksSource}}}
          fetchPolicy="network-only"
        >
          {({data, loading, error, refetch}) => {
            if (loading) {
              return (
                <div className={classes.toolbar}>
                  {addButton}
                  <div style={{flex: 1}}/>
                  {note ? null : addNoteBtn}
                  {addToFavorites}
                  <CircularProgress size={24} thickness={5}/>
                </div>
              );
            }

            if (error) {
              return (
                <div className={classes.toolbar}>
                  {addButton}
                  <div style={{flex: 1}}/>
                  {note ? null : addNoteBtn}
                  {addToFavorites}
                </div>
              );
            }

            return (
              <React.Fragment>
                {openAdd ? (
                  <TaskAdd
                    open={openAdd}
                    onClose={() => this.setState({openAdd: false})}
                    onSubmitted={newTask => {
                      this.addTask(newTask, refetch);
                      this.setState({openAdd: false});
                    }}
                    entity={entity}
                  />
                ) : null}
                <div className={classes.toolbar}>
                  {addButton}
                  <AccessComponent
                    condition={selfUser.id && (hasRole(selfUser.role, userRoles.MANAGER) || hasRole(selfUser.role, userRoles.ADMIN))}
                  >
                    {allTasksButton}
                  </AccessComponent>
                  <div style={{flex: 1}}/>
                  {note ? null : addNoteBtn}
                  {addToFavorites}
                </div>
                <div className={classes.taskList}>
                  {data.tasks.items.map(task => {
                    return (
                      <Paper
                        key={task.id}
                        elevation={0}
                        className={classNames(classes.task, {
                          [classes.closed]: task.closed,
                          [classes.expired]: task.expired,
                        })}
                        onClick={() => {
                          window.location.href = `${match.url}#/tasks/${task.id}`;
                        }}
                      >
                        <Checkbox
                          className={classes.checkbox}
                          checked={task.closed}
                          onClick={event => {
                            if (task.closed) {
                              this.reopenTask(task.id);
                            } else {
                              this.closeTask(task.id);
                            }
                            event.stopPropagation();
                          }}
                          style={{
                            margin: '6px 12px',
                            width: 24,
                            height: 24,
                            padding: 0,
                          }}
                        />
                        <div style={{flex: 1, overflow: 'hidden'}}>
                          <Typography className={classes.title} variant="body1" noWrap>{task.title}</Typography>
                          <div style={{display: 'flex'}}>
                            <Typography variant="caption" className={classes.datetime}>
                              {formatDate(task.deadline, 'EEEEEE, dd MMM yyyy, HH:mm')}
                            </Typography>
                            <Typography variant="caption">
                              {task.assignee.name}
                            </Typography>
                          </div>
                        </div>
                      </Paper>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          }}
        </Query>
        {showClosed ? (
          <React.Fragment>
            <Typography variant="subtitle1" className={classes.closedTitle}>Закрытые задачи</Typography>
            <Query
              query={TaskListQuery}
              variables={{filter: {entityId: entity.id, source: tasksSource, closed: true}}}
              fetchPolicy="network-only"
            >
              {({data, loading, error}) => {
                if (loading) {
                  return (
                    <div className={classes.status}>
                      <CircularProgress size={24} thickness={5}/>
                    </div>
                  );
                }

                if (error) {
                  return (
                    <div className={classes.status}>
                      <icons.Warning color="primary"/>
                    </div>
                  );
                }

                return data.tasks.items.map(task => (
                  <Paper
                    key={task.id}
                    elevation={0}
                    className={classNames(classes.task, {
                      [classes.closed]: task.closed,
                      [classes.expired]: task.expired,
                    })}
                    onClick={() => {
                      window.location.href = `${match.url}#/tasks/${task.id}`;
                    }}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      checked={task.closed}
                      onClick={event => {
                        if (task.closed) {
                          this.reopenTask(task.id);
                        } else {
                          this.closeTask(task.id);
                        }
                        event.stopPropagation();
                      }}
                      style={{
                        margin: '6px 12px',
                        width: 24,
                        height: 24,
                        padding: 0,
                      }}
                    />
                    <div style={{flex: 1, overflow: 'hidden'}}>
                      <Typography className={classes.title} variant="body1" noWrap>{task.title}</Typography>
                      <div style={{display: 'flex'}}>
                        <Typography variant="caption" className={classes.datetime}>
                          {formatDate(task.deadline, 'EEEEEE, dd MMM yyyy, HH:mm')}
                        </Typography>
                        <Typography variant="caption">
                          {task.assignee.name}
                        </Typography>
                      </div>
                    </div>
                  </Paper>
                ));
              }}
            </Query>
            <Button
              size="small"
              fullWidth
              onClick={() => this.setState({showClosed: false})}
              className={classes.showClosedBtn}
            >
              скрыть закрытые
            </Button>
          </React.Fragment>
        ) : (
          <Button
            size="small"
            fullWidth
            onClick={() => this.setState({showClosed: true})}
            className={classes.showClosedBtn}
          >
            показать закрытые
          </Button>
        )}
      </div>
    );
  }
}

const styles = () => ({
  toolbar: {
    margin: '0 8px 8px 8px',
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    marginLeft: -8,
    marginRight: 8,
  },
  task: {
    margin: '8px 0',
    padding: '6px 12px',
    display: 'flex',
    alignItems: 'center',
  },
  closed: {
    background: colors.grey[200],
    '& $title': {
      textDecoration: 'line-through',
    },
  },
  expired: {
    '& $datetime': {
      color: colors.red[500],
    },
  },
  title: {},
  datetime: {
    flex: 1,
  },
  checkbox: {
    marginLeft: -4,
    marginRight: 6,
    width: 32,
    height: 32,
  },
  showClosedBtn: {
    marginBottom: 16,
  },
  closedTitle: {
    marginTop: 16,
    marginLeft: 8,
  },
  dialogIcon: {
    width: 36,
    height: 36,
    padding: 4,
  },
});

EntityTaskList = connect(
  state => ({
    selfUser: state.root.selfUser,
  })
)(EntityTaskList);

export default withRouter(withApollo(withStyles(styles)(EntityTaskList)));
