import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as icons from '@material-ui/icons';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import RemoveOffer from '../form/RemoveOffer';
import base64url from '../../../utils/base64url';
import OfferListItem from '../../../components/relations/OfferListItem';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import {showResponseError} from '../../../utils';

class Offers extends Component {
  state = {
    dealOffers: [],
    error: null,
    loading: false,
    openMenu: null,
    removeOffer: null,
    openStatusMenu: null,
    offerDeclined: false,
  };

  handleOpenMenu = (target, offer) => {
    this.setState({
      openMenu: {target, offer},
    });
  };

  handleCloseMenu = () => {
    this.setState({
      openMenu: null,
    });
  };

  handleMenuItemClick = () => {
    this.setState({
      openMenu: null,
      removeOffer: {
        requestId: this.props.data.id,
        offerId: this.state.openMenu.offer.id,
      },
    });
  };

  handleClickAdd = () => {
    const {data, history} = this.props;
    const filter = {type: data.type};
    history.push(
      `/requests/${data.id}/offers?${base64url.encode(JSON.stringify(filter))}`
    );
  };

  handleClickTrackView = () => {
    const {data, updateActivity, reload} = this.props;

    axios
      .post('/api/v1/trackRequestView', {id: data.id})
      .then(res => {
        if (res.status === 200) {
          updateActivity && updateActivity();
          reload && reload();
        } else {
          throw new Error('Неизвестная ошибка');
        }
      })
      .catch(error => {
        showResponseError(error.response);
      });
  };

  render() {
    const {error, loading, reload, data} = this.props;
    const {openMenu, removeOffer} = this.state;

    return (
      <List
        subheader={
          <ListSubheader disableSticky style={{position: 'relative'}}>
            Листинги: {data.offers.length} / Показы: {data.views || 0}
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={() => this.handleClickTrackView()}
              style={{fontSize: 10, padding: 2, marginLeft: 5}}
            >
              <icons.Add style={{fontSize: 18}} />
              выполнен показ
            </Button>
            <ListItemSecondaryAction>
              {loading ? (
                <IconButton disabled>
                  <CircularProgress size={24} />
                </IconButton>
              ) : error ? (
                <IconButton disabled>
                  <icons.ErrorOutline />
                </IconButton>
              ) : (
                <IconButton onClick={() => this.handleClickAdd()}>
                  <icons.Add />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListSubheader>
        }
      >
        {data.offers && data.offers.length
          ? data.offers.map(offer => (
            <OfferListItem
              key={offer.id}
              offer={offer}
              onSecondaryActionClick={e =>
                this.handleOpenMenu(e.currentTarget, offer)
              }
              linkTo={`/offers/${offer.id}`}
              showAddress
            />
          ))
          : null}

        <Menu
          anchorEl={openMenu ? openMenu.target : null}
          open={Boolean(openMenu)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleMenuItemClick}>Убрать листинг</MenuItem>
        </Menu>

        <RemoveOffer
          open={Boolean(removeOffer)}
          initialValues={removeOffer}
          onSubmitted={() => {
            this.setState({removeOffer: null});
            this.props.updateActivity();
            reload();
          }}
          onClose={() => this.setState({removeOffer: null})}
        />
      </List>
    );
  }
}

export default withRouter(
  connect(
    () => ({}),
    {}
  )(Offers)
);
