import {format} from 'date-fns/esm';
import axios from 'axios';
import locale from 'date-fns/locale/ru';
import {showModal} from '../components/ModalManager';


export const getImageUrl = ({filter, width, height, path}) => {
  if ('https://' === path.substr(0, 8)) {
    return path;
  }

  return `/resized/${filter}_${width}x${height}/${path}.jpg`;
};

export const ImageFilters = {
  resize: 'resize',
  crop: 'crop',
};

export const mapLabels = (variants, valueName, labelName) => {
  return variants.reduce((result, item) => {
    result[item[valueName]] = item[labelName];
    return result;
  }, {});
};

export const mapLabelsBack = labels => {
  let variants = [];
  Object.keys(labels).forEach(function (value) {
    variants.push({'label': labels[value], 'value': value});
  });
  return variants;
};

export const getLabelsForValue = (variants, value, valueName, labelName) => {
  valueName = valueName || 'value';
  labelName = labelName || 'label';

  if (!Array.isArray(value)) {
    return [];
  }

  const labels = [];
  for (const variant of variants) {
    if (value.indexOf(variant[valueName]) >= 0) {
      labels.push(variant[labelName]);
    }
  }
  return labels;
};

export const createAreaFormatter = areaUnits => {
  const areaUnitLabels = mapLabels(areaUnits, 'value', 'label');

  return area => (
    [
      Number(area.amount).toLocaleString(),
      areaUnitLabels[area.unit] || area.unit,
    ].join(' ')
  );
};

export const createAreaRangeFormatter = areaUnits => {
  const areaUnitLabels = mapLabels(areaUnits, 'value', 'label');

  return area => (
    [
      Number(area.from).toLocaleString(),
      '-',
      Number(area.to).toLocaleString(),
      areaUnitLabels[area.unit] || area.unit,
    ].join(' ')
  );
};

export const createPriceFormatter = (priceTypes, currencyCodes) => {
  const currencyCodeLabels = mapLabels(currencyCodes, 'code', 'label');
  const priceTypeLabels = mapLabels(priceTypes, 'value', 'label');
  return price => {
    if (price && price.value)
      return [
        Number(price.value.amount).toLocaleString(),
        currencyCodeLabels[price.value.currency] || price.value.currency,
        priceTypeLabels[price.type] || price.type,
      ].join(' ');
  };
};

export const createListPriceFormatter = currencyCodes => {
  const currencyCodeLabels = mapLabels(currencyCodes, 'code', 'label');

  return price => (
    [
      Number(price.value.amount).toLocaleString(),
      currencyCodeLabels[price.value.currency] || price.value.currency,
    ].join(' ')
  );
};

export const formatAddress = address => {
  return [formatAddressLine1(address), formatAddressLine2(address)].join('\n');
};

export const formatAddressLine1 = address => {
  let result = '';

  if (!address.attributes) {
    return result;
  }

  const hasRegion = address.attributes.region_with_type && (address.attributes.region_with_type !== address.attributes.city_with_type);

  if (address.attributes.city_with_type) {
    result += address.attributes.city_with_type;

    if (hasRegion) {
      result += ', ';
    }
  }

  if (hasRegion) {
    result += address.attributes.region_with_type;
  }

  return result;
};

export const formatAddressLine2 = address => {
  let result = '';

  if (address.street) {
    result += address.street.trim();
  }

  if (!address.building) {
    return result;
  }

  if (address.building.number) {
    result += ` д. ${address.building.number.trim()}`;
  }

  if (address.building.block) {
    result += ` к. ${address.building.block.trim()}`;
  }

  if (address.building.letter) {
    result += ` стр. ${address.building.letter.trim()}`;
  }

  return result;
};

export const formatAddressListItem = address => {
  let result = formatAddressLine2(address);

  if (result.length > 0) {
    result += ', ';
  }

  result += formatAddressLine1(address);

  return result;
};

export const formatAddressListItemTwoLines = address => {
  return formatAddressLine2(address).length > 0 ? [formatAddressLine2(address), formatAddressLine1(address)].join('\n') : formatAddressLine1(address);
};

export const shortAddress = address => {
  let result = '';
  if (address.street) {
    result += address.street.trim() + '\n';
  }

  if (!address.building) {
    return result;
  }

  if (address.building.number) {
    result += `д. ${address.building.number.trim()} `;
  }

  if (address.building.block) {
    result += `к. ${address.building.block.trim()} `;
  }

  if (address.building.letter) {
    result += `стр. ${address.building.letter.trim()} `;
  }

  return result;
};


export const formatDate = date => date ? format(new Date(date), 'dd.MM.yyyy, HH:mm') : '';

export const formatDateWithoutTime = date => date ? format(new Date(date), 'dd.MM.yyyy') : '';

export const formatFullDate = data => data ? format(new Date(data), 'dd MMMM YYYY', {
  locale,
  awareOfUnicodeTokens: true,
}) : '';

export const formatFileSize = bytes => {
  if (Math.abs(bytes) < 1024) {
    return bytes + ' Б';
  }

  const units = ['КБ', 'МБ', 'ГБ'];
  let u = -1;
  do {
    bytes /= 1024;
    ++u;
  } while (Math.abs(bytes) >= 1024 && u < units.length - 1);

  return bytes.toFixed(1) + '  ' + units[u];
};

export const phoneToHref = phone => 'tel:' + phone.replace(/[^+\d]/g, '');

export const getCallContact = requestId => `/requests/${requestId}/contact/`;

export const emailToHref = email => 'mailto:' + email;

export const normalizePrice = (price, area) => {
  let normalizedArea = area.amount;
  switch (area.unit) {
    case 'ha': {
      normalizedArea *= 10000;
      break;
    }
    case 'a': {
      normalizedArea *= 100;
      break;
    }
    default:
  }

  let normalizedPrice = price.value.amount;
  switch (price.type) {
    case 'sell': {
      return {
        value: {
          amount: normalizedPrice / normalizedArea,
          currency: price.value.currency,
        },
        type: 'sell_m2',
      };
    }
    case 'sell_m2': {
      return {
        value: {
          amount: normalizedPrice * normalizedArea,
          currency: price.value.currency,
        },
        type: 'sell',
      };
    }
    case 'rent_per_month': {
      return {
        value: {
          amount: normalizedPrice / normalizedArea,
          currency: price.value.currency,
        },
        type: 'rent_m2_per_month',
      };
    }
    case 'rent_m2_per_month': {
      return {
        value: {
          amount: normalizedPrice * normalizedArea,
          currency: price.value.currency,
        },
        type: 'rent_per_month',
      };
    }
    default:
      return price;
  }
};

export const calculateOfferCommission = (revenue, price, area) => {
  let normalizedArea = area.amount;
  switch (area.unit) {
    case 'ha': {
      normalizedArea *= 10000;
      break;
    }
    case 'a': {
      normalizedArea *= 100;
      break;
    }
    default:
  }

  let normalizedPrice = price.value.amount;
  if (price.type === 'sell_m2'
    || price.type === 'rent_m2_per_month'
  ) {
    normalizedPrice *= normalizedArea;
  }

  return Math.round(normalizedPrice * revenue / 100 * 0.5);
};

export const calculateOfferCommissionFull = (revenue, price, area) => {
  let normalizedArea = area.amount;
  switch (area.unit) {
    case 'ha': {
      normalizedArea *= 10000;
      break;
    }
    case 'a': {
      normalizedArea *= 100;
      break;
    }
    default:
  }

  let normalizedPrice = price.value.amount;
  if (price.type === 'sell_m2'
    || price.type === 'rent_m2_per_month'
  ) {
    normalizedPrice *= normalizedArea;
  }

  return Math.round(normalizedPrice * revenue / 100);
};

export const calculateAgentOfferCommission = (offer, selfUser, factor) => {
  const {revenue, price, property, type} = offer;
  const commission = selfUser.commission ? selfUser.commission : selfUser.teams[0].avgCommissionMember;
  const area = property.area;

  let normalizedArea = area.amount;
  switch (area.unit) {
    case 'ha': {
      normalizedArea *= 10000;
      break;
    }
    case 'a': {
      normalizedArea *= 100;
      break;
    }
    default:
  }

  let normalizedPrice = price.value.amount;
  if (price.type === 'sell_m2'
    || price.type === 'rent_m2_per_month'
  ) {
    normalizedPrice *= normalizedArea;
  }

  let normalizedCommission = commission ? commission / 100 : 0.43;

  let normalizedRevenue = revenue ? revenue : (type === 'rent' ? 100 : 2);

  return Math.round(normalizedPrice * normalizedRevenue / 100 * normalizedCommission * (factor ? factor : 1));
};

export const getPriceForObj = offer => {
  const {price, property} = offer;
  const {area} = property;

  let normalizedArea = area.amount;
  switch (area.unit) {
    case 'ha': {
      normalizedArea *= 10000;
      break;
    }
    case 'a': {
      normalizedArea *= 100;
      break;
    }
    default:
  }

  let normalizedPrice = Number(price.value.amount);
  let newType = price.type;
  if (price.type === 'sell_m2' || price.type === 'rent_m2_per_month') {
    normalizedPrice *= normalizedArea;
    newType = price.type === 'sell_m2' ? 'sell' : 'rent_per_month';
  }

  let newPrice = {
    ...price,
    value: {
      ...price.value,
      amount: normalizedPrice.toFixed(2),
    },
    type: newType,
  };

  return newPrice;
};

export const getPriceForM = offer => {
  const {price, property} = offer;
  const {area} = property;

  let normalizedArea = area.amount;
  switch (area.unit) {
    case 'ha': {
      normalizedArea *= 10000;
      break;
    }
    case 'a': {
      normalizedArea *= 100;
      break;
    }
    default:
  }

  let normalizedPrice = Number(price.value.amount);
  let newType = price.type;
  if (price.type !== 'sell_m2' && price.type !== 'rent_m2_per_month') {
    normalizedPrice /= normalizedArea;
    newType = price.type === 'sell' ? 'sell_m2' : 'rent_m2_per_month';
  }

  let newPrice = {
    ...price,
    value: {
      ...price.value,
      amount: normalizedPrice.toFixed(2),
    },
    type: newType,
  };

  return newPrice;
};

export const executeQuery = (query, variables = {}, conf = {}) => axios.post('/api/query/', {query, variables}, conf);

export const getUniqueArray = array => {
  return [...new Set(array)];
};

export const drawFavicon = text => {
  if (!text)
    return;
  let canvas = document.createElement('canvas');
  canvas.height = canvas.width = 16;
  let context = canvas.getContext('2d');
  context.font = 'bold 14px "Roboto", "Helvetica", "Arial"';
  context.arc(8, 8, 8, 0, 2 * Math.PI, false);
  context.fillStyle = 'red';
  context.fill();

  context.fillStyle = '#FFF';
  context.fillText(text >= 9 ? 9 : text, 4, 13, 16);
  return canvas.toDataURL();
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const showResponseError = (response, SubmissionError, set) => {
  if (response === undefined) {
    showModal('Произошла ошибка');
    return;
  }

  if (response.status === 400) {
    if (typeof response.data === 'object' && typeof response.data.error === 'object' && response.data.error.errors) {
      showFormErrors(response.data.error.errors, SubmissionError, set);
      return;
    }
  }

  let message = 'Произошла ошибка';

  if (response.status === 404) {
    message = 'Данные не найдены';
  }

  if (response.status === 403) {
    message = 'У вас недостаточно прав для выполнения данного действия';
  }

  if (typeof response.data === 'object' && typeof response.data.error === 'string') {
    message = response.data.error;
  }

  showModal(message);
};

export const showFormErrors = (errors, SubmissionError, set) => {
  if (SubmissionError === undefined || set === undefined) {
    return;
  }

  if (typeof errors === 'object' && errors && errors.length) {
    throw new SubmissionError(
      errors.reduce(
        (_errors, {propertyPath, message}) => {
          return set(_errors, propertyPath, message);
        },
        {}
      )
    );
  }
};

export const numberWithSpaces = x => {
  if (!x) return x;
  let parts = x.toString().replace(',', '.').replace(/\s/g, '').split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const copyToClipboard = (text, eventTarget) => {
  return new Promise((resolve, reject) => {
    if (!window.navigator.clipboard) {
      fallbackCopyTextToClipboard(text, resolve, reject, eventTarget);
      return;
    }

    try {
      if (window.navigator.permissions
        && typeof window.navigator.permissions.query === 'function'
        && /chrom(e|ium)/.test(window.navigator.userAgent.toLowerCase())
      ) {
        window.navigator.permissions.query({name: 'clipboard-write'})
          .then(result => {
            if (result.state === 'denied') {
              fallbackCopyTextToClipboard(text, resolve, reject, eventTarget);
            }
          });
      }

      window.navigator.clipboard
        .writeText(text)
        .then(function (){
          resolve();
        })
        .catch(function () {
          fallbackCopyTextToClipboard(text, resolve, reject, eventTarget);
        });
    } catch (e) {
      fallbackCopyTextToClipboard(text, resolve, reject, eventTarget);
    }
  });
};

function fallbackCopyTextToClipboard(text, resolve, reject, eventTarget) {
  let textArea = document.createElement('textarea'), success = true;

  textArea.value = text;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  textArea.readOnly = true;

  if (eventTarget) {
    eventTarget.appendChild(textArea);
  } else {
    document.body.appendChild(textArea);
  }

  textArea.focus();
  textArea.select();

  try {
    if (!document.execCommand('copy')) {
      throw new Error('access to clipboard denied');
    }
  } catch (err) {
    window.prompt('Скопировать в буфер обмена: Ctrl+C, Enter', text);
    success = false;
    reject();
  }

  textArea.remove();

  if(success){
    resolve();
  }
}

let copyToClipboardFeedbackTimeout = null;
export const copyToClipboardFeedback = (show, hide) => {
  show();

  if(copyToClipboardFeedbackTimeout){
    clearTimeout(copyToClipboardFeedbackTimeout);
  }
  copyToClipboardFeedbackTimeout = setTimeout(() => hide(), 2000);
};

export const getMenuSettings = user => {
  if (!user || !user.teams || !user.teams[0] || !user.teams[0].menu) {
    return {deals: false, requests: false};
  }

  return user.teams[0].menu;
};

export const isSingleDealMenuSection = user => {
  const menu = getMenuSettings(user);
  return (menu.deals && !menu.requests) || (!menu.deals && menu.requests);
};

export const needUpdatePageTitleByDealMenuSection = (prevProps, nextProps) => {
  return nextProps.selfUser && nextProps.selfUser.id && prevProps.selfUser && !prevProps.selfUser.id;
};

export const roundToXDigits = (value, digits) => {
  if (!digits) {
    digits = 2;
  }

  value = value * Math.pow(10, digits);
  value = Math.round(value);
  value = value / Math.pow(10, digits);

  return value;
};

export const getWindowWidth = () => {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};


export const isMobileWindowWidth = () => {
  return getWindowWidth() < 900;
};

export const makeGUID = () => {
  const S4 = function () {
    return(
      Math.floor(
        Math.random() * 0x10000 /* 65536 */
      ).toString(16)
    );
  };
  return (
    S4() + S4() + '-' +
    S4() + '-' +
    S4() + '-' +
    S4() + '-' +
    S4() + S4() + S4()
  );
};
