import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import {queryOfferRequestsAnalytics} from '../../../queries';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors/index';
import DatePicker from '../../../components/Input/DatePicker';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as icons from '@material-ui/icons';
import {clone} from 'lodash';

class OfferRequestsAnalytics extends Component {
  state = {
    loading: true,
    error: false,
    items: [],
    dateFrom: null,
    dateTo: null,
  };

  componentDidMount() {
    const {offerId} = this.props;
    const {dateFrom, dateTo} = this.state;

    this.load(offerId, dateFrom, dateTo);
  }

  load(offerId, dateFrom, dateTo) {
    this.setState({
      loading: true,
      dateFrom: dateFrom,
      dateTo: dateTo,
    });

    queryOfferRequestsAnalytics({offerId, dateFrom, dateTo})
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            items: resp.data.data.offerRequestsAnalytics,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  render() {
    const {
      onClose,
      open,
      classes,
      offerId,
    } = this.props;
    let {dealSources} = this.props;
    const {loading, error, items, dateFrom, dateTo} = this.state;
    const emptyState = !(items && items.length > 0);
    let stats = {};
    items.forEach(item => {
      stats[item.source] = item.count;
    });
    return (
      <Dialog fullWidth maxWidth="md" open={open}>
        <DialogTitle>Аналитика обращений</DialogTitle>
        <Divider/>
        <DialogContent className={classes.dialog}>
          <div className={classes.filter}>
            <Grid container spacing={8}>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <DatePicker
                    format="yyyy-MM-dd"
                    clearable
                    input={{
                      value: dateFrom,
                      onChange: value => {
                        this.load(offerId, value, dateTo);
                      },
                    }}
                    meta={{}}
                    label={'Дата начала периода'}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                  <DatePicker
                    format="yyyy-MM-dd"
                    clearable
                    input={{
                      value: dateTo,
                      onChange: value => {
                        this.load(offerId, dateFrom, value);
                      },
                    }}
                    meta={{}}
                    label={'Дата конца периода'}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                {
                  dealSources.map((source, index) => {
                    if (typeof stats[source.value] === 'undefined') {
                      return null;
                    }
                    return (
                      <TableCell key={index}>{source.label}</TableCell>
                    );
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody className={loading || error || emptyState ? classes.progressWrapper : ''}>
              {
                loading ? (
                  <CircularProgress size={50} thickness={4} className={classes.progress}/>
                ) : (
                  error ? (<icons.ErrorOutline className={classes.progress}/>) : (
                    !emptyState ? (
                      <TableRow  className={classes.tableRow}>
                        {
                          dealSources.map((source, index) => {
                            if (typeof stats[source.value] === 'undefined') {
                              return null;
                            }
                            return (
                              <TableCell key={index}>{stats[source.value] || '0'}</TableCell>
                            );
                          })
                        }
                      </TableRow>
                    ) : (
                      <div className={classes.emptyState}>Нет данных</div>
                    )
                  )
                )
              }
            </TableBody>
          </Table>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            onClick={onClose}
          >
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = () => ({
  dialog: {
    padding: 0,
  },
  filter: {
    padding: '0 20px',
  },
  table: {
    width: '100%',
  },
  tableRow: {
    '& > td, & > th': {
      textAlign: 'center',
      verticalAlign: 'middle',
      whiteSpace: 'break-spaces',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  progressWrapper: {
    position: 'relative',
    height: 200,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  emptyState: {
    fontSize: '3rem',
    position: 'absolute',
    top: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: colors.grey[500],
  },
});

export default OfferRequestsAnalytics = connect(
  state => ({
    dealSources: (() => {
      let dealSources = clone(state.root.classification.dealSources);
      dealSources.unshift({value: 'total', label: 'Всего'});
      dealSources.push({value: 'avitoDays', label: 'дней на Авито'});
      return dealSources;
    })(),
  }), {}
)(withStyles(styles)(OfferRequestsAnalytics));
