import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {connect} from 'react-redux';
import {capitalizeFirstLetter, formatDateWithoutTime, mapLabels} from '../../utils';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

class RequestListItem extends Component {
  getCurrentFeedback() {
    const {data, requestFeedbackLabels} = this.props;
    let currentFeedback = null;
    Object.keys(requestFeedbackLabels).forEach(feedback => {
      if (data['feedback' + capitalizeFirstLetter(feedback)] === true) {
        currentFeedback = feedback;
        return false;
      }
    });
    return currentFeedback;
  }

  render() {
    const {
      data,
      onSecondaryActionClick,
      requestStatuses,
      requestFeedbackLabels,
      requestFeedbackColors,
    } = this.props;

    let listItemProps = {};

    if (this.props.handleDialogOpen) {
      listItemProps = {
        onClick: e => {
          e.preventDefault();
          this.props.handleDialogOpen(data.id);
        },
      };
    }

    const currentFeedback = this.getCurrentFeedback();

    return (
      <ListItem
        dense
        button
        component={Link}
        to={`/requests/${data.id}`}
        {...listItemProps}
      >
        <Avatar style={{backgroundColor: colors.purple[300]}}>
          <icons.Inbox/>
        </Avatar>
        {data ? (
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body1">
                № {data.no} от {formatDateWithoutTime(data.date)}
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography variant="body2">
                  <span style={{background: (requestFeedbackColors[currentFeedback]), color: '#fff', padding: '0 5px'}}>
                    {requestFeedbackLabels[currentFeedback] || currentFeedback}
                  </span>
                  <span style={{marginLeft: 5}}>
                    {requestStatuses[data.status] || data.status}
                  </span>
                </Typography>
              </React.Fragment>
            }
          />
        ) : null}
        {onSecondaryActionClick ? (
          <ListItemSecondaryAction>
            <IconButton onClick={onSecondaryActionClick}>
              <icons.MoreVert/>
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  }
}

RequestListItem = connect(
  state => ({
    requestStatuses: (() => {
      let labels = mapLabels(state.root.classification.requestStatuses, 'value', 'label');
      labels[3] = <span style={{color: colors.purple[600]}}>{labels[3]}</span>;
      return labels;
    })(),
    requestFeedbackLabels: mapLabels(state.root.classification.requestFeedback, 'value', 'label'),
    requestFeedbackColors: mapLabels(state.root.classification.requestFeedback, 'value', 'color'),
  }),
)(RequestListItem);

export default RequestListItem;
