import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import RequestDetails from './RequestDetails';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as icons from '@material-ui/icons';
import {formatDateWithoutTime, mapLabels} from '../../utils';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import {Link} from 'react-router-dom';

class DealDialog extends Component {
  render() {
    const {
      classes,
      handleClose,
      open,
      handleClick,
      data,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{paper: classes.dialog}}
      >
        {data ? (
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle}>
              <div className={classes.dialogTitleWrap}>
                <div className={classes.dialogText}>
                  <Typography variant="h6">№ {data.no} от {formatDateWithoutTime(data.date)}</Typography>
                </div>
                <div className={classes.dialogBtn}>
                  <IconButton
                    onClick={() => handleClick && handleClick()}
                    component={Link}
                    to={`/requests/${this.props.data.id}`}
                    classes={{root: classes.dialogIcon}}
                  >
                    <icons.OpenInNew/>
                  </IconButton>
                  <IconButton
                    onClick={handleClose}
                    classes={{root: classes.dialogIcon}}
                  >
                    <icons.Close/>
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <Divider/>
            <DialogContent className={classes.dialogContent}>
              <RequestDetails
                data={data}
                showRequirements
              />
            </DialogContent>
          </React.Fragment>
        ) : null}
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  dialogIcon: {
    width: 36,
    height: 36,
    padding: 4,
  },
  dialogTitleWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 0,
    minHeight: 150,
  },
  dialogTitle: {
    padding: '12px 24px 8px 24px',
  },
  dialogBtn: {
    marginRight: -12,
    minWidth: 72,
  },
  flexWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});


export default connect(
  state => ({
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
  }),
)(withStyles(styles)(DealDialog));
