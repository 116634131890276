import axios from 'axios';
import {withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography/Typography';
import Dropzone from 'react-dropzone';
import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import {Field} from 'redux-form';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as icons from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import DocumentListItem from '../../../components/relations/DocumentListItem';
import TextField from '../../../components/Input/TextField';
import {formatFileSize} from '../../../utils';

class AddDocument extends Component {
  state = {
    uploading: false,
    document: null,
    addDocument: false,
    openMenu: false,
  };

  handleDrop = files => {
    const {change} = this.props;

    if (files.length < 1) {
      return;
    }

    this.setState({uploading: true});
    axios.post(`/api/files/${encodeURIComponent(files[0].name)}`, files[0], {
      headers: {
        'Content-Type': files[0].type,
      },
    })
      .then(resp => {
        change('document', {
          ...resp.data,
          name: resp.data.fileName,
        });
        this.setState({document: resp.data});
      })
      .catch(error => {
        if (error.response.status === 400) {
          alert(error.response.data.error.message);
        } else {
          alert('Ошибка при загрузке файла');
        }
      })
      .then(() => {
        this.setState({uploading: false});
      });
  };

  render() {
    const {
      classes,
      handleSubmit,
      submitting,
      task,
    } = this.props;
    const {
      document,
      uploading,
      addDocument,
      openMenu,
    } = this.state;

    return (
      <>
        {task.documents ? (
          <List subheader={
            <ListSubheader
              disableSticky
              style={{position: 'relative', padding: '0 24px'}}
            >
              Документы
            </ListSubheader>
          }>
            {task.documents.map((document, index) => (
              <DocumentListItem
                key={index}
                document={document}
                onSecondaryActionClick={e => this.setState({openMenu: {target: e.currentTarget, path: document.path}})}
              />
            ))}
          </List>
        ) : null }
        {addDocument ? (
          <div className={classes.documentsWrap}>
            <Typography variant="caption">Добавить документ</Typography>
            {document ? (
              <React.Fragment>
                <Typography variant="body1">{document.fileName}</Typography>
                <Typography variant="caption">{formatFileSize(document.fileSize)}</Typography>
                <Field
                  component={TextField}
                  name="document.name"
                  label="Название"
                  fullWidth
                  margin="normal"
                />
                <Button
                  type="button"
                  color="primary"
                  size="small"
                  variant="contained"
                  disabled={submitting || uploading}
                  onClick={() => this.setState({addDocument: false, document: null})}
                  className={classes.cancelButton}
                >
                  Отмена
                </Button>
                <Button
                  type="button"
                  color="primary"
                  size="small"
                  variant="contained"
                  disabled={submitting || uploading}
                  onClick={handleSubmit(this.submit)}
                >
                  Добавить
                </Button>
              </React.Fragment>
            ) : (
              uploading ? (
                <CircularProgress size={20} thickness={5}/>
              ) : (
                <Dropzone
                  className={classes.dropzone}
                  onDrop={this.handleDrop}
                >
                  <Typography>
                    Перетащите файлы в выделенную область или нажмите сюда для загрузки<br/>(до 10 МБ).
                  </Typography>
                </Dropzone>
              )
            )}
          </div>
        ) : (
          <div className={classes.documentsWrap}>
            <Button
              type="button"
              color="primary"
              disabled={submitting || uploading}
              onClick={() => this.setState({addDocument: true})}
              size="small"
              variant="contained"
            >
              <icons.AddOutlined/> документ
            </Button>
          </div>
        )}
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => {
              this.removeDocument();
            }}>
              Удалить документ
            </MenuItem>
          </Menu>
        ) : null}
      </>
    );
  }

  submit = data => {
    this.props.onAddDocument(data)
      .then(() => this.setState({document: null, addDocument: false}));
  };

  removeDocument = () => {
    const {openMenu} = this.state;
    this.props.onRemoveDocument(openMenu.path)
      .then(() => this.setState({openMenu: false}));
  };
}

const styles = theme => ({
  dropzone: {
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    border: '1px dashed silver',
    textAlign: 'center',
  },
  documentsWrap: {
    padding: '0 24px 24px',
  },
  cancelButton: {
    marginRight: theme.spacing.unit,
  },
});

export default reduxForm({
  form: 'add_document_to_task',
  enableReinitialize: true,
})(withStyles(styles)(AddDocument));
