import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import * as colors from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import * as icons from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';

class PropertyDetails extends Component {
  state = {
    telegramNotifications: {},
    error: false,
  };

  componentDidMount() {
    const {sections, selfUser} = this.props;
    const disabled = selfUser.telegram_disabled_noty || {};
    let telegramNotifications = {};
    Object.entries(sections).forEach(function(entry){
      const [section] = entry;
      telegramNotifications[section] = !disabled[section];
    });
    this.setState({telegramNotifications: telegramNotifications});
  }

  onChangeSection = (section, enabled) => {
    this.setState(prevState => ({
      error: false,
      telegramNotifications: {
        ...prevState.telegramNotifications,
        [section]: enabled,
      },
    }));

    axios.post('/api/users/self/telegramNotifications', {section, disabled: !enabled})
      .then(res => {
        if (!res || !res.data || !res.data.success) {
          this.setState({error: true});
        }
      })
      .catch(() => {
        this.setState({error: true});
      });
  };

  render() {
    const {classes, sections} = this.props;
    const {error, telegramNotifications} = this.state;

    return (
      <div className={classes.container}>
        <List>
          <ListItem dense>
            <Typography>Уведомления Telegram</Typography>
          </ListItem>
          <ListItem dense>
            {Object.entries(telegramNotifications).map(entry => {
              const [section, value] = entry;
              const label = sections[section];
              return (
                <FormControlLabel
                  key={section}
                  control={<Checkbox
                    checked={value}
                    onChange={() => this.onChangeSection(section, !telegramNotifications[section])}/>
                  }
                  label={label}
                />
              );
            })}
          </ListItem>
          <Snackbar
            open={Boolean(error)}
            autoHideDuration={4000}
            onClose={() => this.setState({error: false})}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            className={classes.errorWrapper}
            classes={{anchorOriginBottomLeft: classes.errorMessageMobile}}
          >
            <Typography className={classes.errorMessage}>
              <icons.Warning className={classes.errorIcon}/>
              Произошла ошибка при сохранении настроек
            </Typography>
          </Snackbar>
        </List>
      </div>
    );
  }
}

const styles = () => ({
  button: {
    marginLeft: '-10px',
  },
  table: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.grey[300],
    borderRadius: 5,
    borderCollapse: 'separate',
  },
  errorWrapper: {
    backgroundColor: colors.red[400],
    padding: 10,
    borderRadius: 5,
  },
  errorIcon: {
    color: '#FFF',
    marginBottom: -2,
    marginRight: 2,
    height: 16,
    width: 16,
  },
  errorMessage: {
    color: '#FFF',
  },
  errorMessageMobile: {
    right: 'auto',
    left: 24,
    bottom: 24,
  },
  container: {
    background: 'white',
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.grey[300],
    margin: '10px 0',
  },
});

export default connect(
  ({root}) => ({
    selfUser: root.selfUser,
    sections: {
      'request': 'Заявки beta',
      'task': 'Задачи',
      'deal': 'Заявки',
      'dealOffer': 'Сделки',
      'contact': 'Контакты',
      // 'offer': 'Листинги',
    },
  }),
  {}
)(withStyles(styles)(PropertyDetails));
