import React, {Component} from 'react';
import ReactDOM, {createPortal} from 'react-dom';
import {Dialog} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

class ModalManager {
  constructor() {
    this.state = {
      isOpen: false,
      props: {},
      ModalComponent: null,
    };
    this.root = document.createElement('div');
    document.body.appendChild(this.root);
  }

  show(props = {}, ModalComponent = DefaultModalComponent) {
    this.state = {
      isOpen: true,
      props,
      ModalComponent,
    };
    this.render();
  }

  hide() {
    this.state.isOpen = false;
    this.render();
  }

  render() {
    const {isOpen, ModalComponent, props} = this.state;
    if (isOpen && ModalComponent) {
      ReactDOM.render(
        createPortal(
          <>
            <Dialog
              open={isOpen}
              onClose={() => this.hide()}
              maxWidth="sm"
              fullWidth
            >
              <ModalComponent closeModal={() => this.hide()} {...props} />
            </Dialog>
          </>,
          this.root
        ),
        this.root
      );
    } else {
      ReactDOM.render(null, this.root);
    }
  }
}

export class DefaultModalComponent extends Component {
  render() {
    const {closeModal, title, message, description} = this.props;
    return (
      <>
        {title && <DialogTitle>{title || ''}</DialogTitle>}
        <Divider />
        <DialogContent style={{marginTop: 20, marginBottom: 20, padding: 20}}>
          <Typography variant="subtitle1">{message}</Typography>
          {description && (
            <Typography
              variant="body1"
              color="textSecondary"
              style={{marginTop: 20}}
            >
              {description}
            </Typography>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="reset" onClick={closeModal}>
            Закрыть
          </Button>
        </DialogActions>
      </>
    );
  }
}

export const showModal = (message, title, ModalComponent) => {
  const modal = new ModalManager();
  modal.show({title, message}, ModalComponent);
};
