import React, {Component} from 'react';
import {Query, withApollo} from 'react-apollo';
import {connect} from 'react-redux';
import {find} from 'lodash';
import pathtoRegexp from 'path-to-regexp';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {MenuItem} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import DatePicker from 'material-ui-pickers/DatePicker';
import ContainerDimensions from 'react-container-dimensions';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import {eachDayOfInterval, endOfMonth, format, parseISO, startOfMonth} from 'date-fns/esm';
import axios from 'axios';

import StatisticType from './components/StatisticType';
import Sidebar from './components/Sidebar';
import {
  offersWithoutDealList,
  queryDealOfferStatistic,
  queryDynamicStatics,
  queryRequestDynamicStatics,
  queryRequestOfferStatistic,
  queryRequestStatistic,
  queryRequestWithoutOffersStatistics,
  queryStatistic,
} from '../../queries/AnalyticsQuery';
import BarChart from './components/BarChart';
import base64url from '../../utils/base64url';
import AccessComponent from '../../components/AccessComponent';
import DynamicsTable from './components/DynamicsTable';
import DealOfferTable from './components/DealOfferTable';
import OfferWithoutDealTable from './components/OfferWithoutDealTable';
import Typography from '@material-ui/core/Typography';
import {getSection, setSection} from '../../utils/lastSection';
import InputBase from '@material-ui/core/InputBase';
import {getMenuSettings} from '../../utils';
import AnalyticsArchivedOffers from './AnalyticsArchivedOffers';

class AnalyticsPage extends Component {
  state = {
    filter: {},
    dynamicsFilter: {},
    query: {},
    searchAddress: '',
    searchAddressDebounce: null,
    openSidebar: false,
    startOfMonth: null,
    endOfMonth: null,
    monthDays: [],
    members: [],
    currentTeam: null,
    currentMember: null,
    entitySource: getSection('analyticsEntitySource') || 'deal',
    chipAppeals: false,
    typeAppeals: 'default',
    platformManagement: true,
  };

  matchPath = pathtoRegexp.compile(this.props.match.path);

  componentDidMount() {
    const {selfUser} = this.props;
    this.setState({platformManagement: this.platformManagementIsChecked()});
    if (selfUser && selfUser.teams) {
      this.loadStaticstics();
    }

    this.search(this.props.location, true);
  }

  componentDidUpdate(prevProps) {
    const {selfUser} = this.props;
    if (selfUser.teams !== prevProps.selfUser.teams) {
      this.loadStaticstics();
    }

    if (prevProps.location !== this.props.location) {
      this.search(this.props.location);
    }
  }

  platformManagementIsChecked() {
    try {
      return localStorage.getItem('analyticsPlatformManagement') === '1';
    } catch (e) {
    }
    return false;
  }

  platformManagementSet(checked) {
    try {
      this.setState({platformManagement: checked});
      localStorage.setItem('analyticsPlatformManagement', checked ? '1' : '0');
    } catch (e) {
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {match} = props;
    const {
      currentTeam,
      startOfMonth,
      endOfMonth,
      query,
      chipAppeals,
      entitySource,
      searchAddressDebounce,
      typeAppeals,
    } = state;
    const inOffersSection = (entitySource === 'appeals' || entitySource === 'request_offers');
    const showOffersDates = chipAppeals && inOffersSection && searchAddressDebounce === null;
    return {
      filter: {
        by: match.params.source === currentTeam ? 'TEAM' : match.params.source === 'team' || match.params.source === 'assigned' || match.params.source === 'all' ? match.params.source.toUpperCase() : match.params.source,
        dateFrom: query.dateFrom ? new Date(query.dateFrom) : null,
        dateTo: query.dateTo ? new Date(query.dateTo) : null,
        updatedAtFrom: showOffersDates ? new Date(query.updatedAtFrom) : undefined,
        updatedAtTo: showOffersDates ? new Date(query.updatedAtTo) : undefined,
        createdAtFrom: showOffersDates ? new Date(query.createdAtFrom) : undefined,
        createdAtTo: showOffersDates ? new Date(query.createdAtTo) : undefined,
        address: inOffersSection ? query.address : undefined,
        typeAppeals: inOffersSection ? typeAppeals : undefined,
        team: currentTeam ? currentTeam : null,
      },
      dynamicsFilter: {
        by: match.params.source === currentTeam ? 'TEAM' : match.params.source === 'team' || match.params.source === 'assigned' || match.params.source === 'all' ? match.params.source.toUpperCase() : match.params.source,
        dateFrom: startOfMonth,
        dateTo: endOfMonth,
        team: currentTeam ? currentTeam : null,
      },
    };
  }

  search(location, first) {
    let query = {};
    const qs = location.search.substr(1);
    if (qs.length > 0) {
      try {
        query = JSON.parse(base64url.decode(qs));
        if (query.dynamicsDate) {
          this.handleDynamic(parseISO(query.dynamicsDate));
        } else {
          this.handleDynamic(new Date());
        }
      } catch (_) {
      }
    } else {
      query = {
        dateFrom: null,
        dateTo: null,
      };
      this.handleDynamic(new Date());
    }

    this.setState({query});

    if (first) { // init input value from query params
      let searchAddress = '';
      if (query.address) {
        searchAddress = query.address;
      }
      this.setState({searchAddress});
    }
  }

  handleSearchAddressInput = e => {
    let {searchAddressDebounce} = this.state;
    if (searchAddressDebounce) {
      clearTimeout(searchAddressDebounce);
    }

    const that = this;
    const value = e.target.value;

    searchAddressDebounce = setTimeout(function () {
      that.handleSearchAddressChange(value);
    }, 500);

    that.setState({searchAddress: value, searchAddressDebounce});
  };

  handleSearchAddressChange = value => {
    this.setState({
      query: {
        ...this.state.query,
        address: value,
      },
      searchAddressDebounce: null,
    });

    this.props.history.push(this.props.match.url + '?' + base64url.encode(JSON.stringify(this.state.query)));
  };

  clearSearchAddress() {
    this.setState({
      query: {
        ...this.state.query,
        address: '',
      },
      searchAddress: '',
    });
  }

  render() {
    const {
      classes,
      selfUser,
      dealStatuses,
      dealSources,
      offerTypes,
      propertyTypes,
      requestActivities,
      history,
      match,
      dealClasses,
      location,
    } = this.props;

    const {
      filter,
      openSidebar,
      dynamicsFilter,
      currentTeam,
      query,
      entitySource,
      chipAppeals,
      typeAppeals,
      searchAddress,
      platformManagement,
    } = this.state;

    const {
      dateFrom,
      dateTo,
      dynamicsDate,
      updatedAtFrom,
      updatedAtTo,
      createdAtFrom,
      createdAtTo,
    } = query;

    const menu = getMenuSettings(selfUser);
    const entitySourceMenu = [];
    if (menu.deals) {
      entitySourceMenu.push(['deal', 'Заявки']);
      entitySourceMenu.push(['appeals', 'Обращения']);
    }
    if (menu.requests) {
      if (!menu.deals) {
        entitySourceMenu.push(['request', 'Заявки']);
        entitySourceMenu.push(['request_offers', 'Обращения']);
      } else {
        entitySourceMenu.push(['request', 'Заявки beta']);
        entitySourceMenu.push(['request_offers', 'Обращения beta']);
      }
    }
    if (selfUser.role === 'ROLE_ADMIN' || selfUser.role === 'ROLE_TEAM_MANAGER') {
      entitySourceMenu.push(['archived_offers', 'Закрытые листинги']);
    }

    if (entitySource === 'archived_offers') {
      return (
        <AnalyticsArchivedOffers
          match={match}
          history={history}
          location={location}
          entitySourceMenu={entitySourceMenu}
          openTeam={currentTeam ? currentTeam : match.params.source}
          onChangeSection={section => {
            setSection('analyticsEntitySource', section);
            this.setState({
              entitySource: section,
              query: {
                ...query,
              },
            });
          }}
          onChangeTeam={team => {
            this.setState(prevState => ({
              filter: {
                ...prevState.filter,
                team: team,
              },
              currentTeam: team,
            }));
            setSection('analyticsAdminSource', team);
            localStorage.removeItem('analyticsAdminAssignees');
          }}
        />
      );
    }

    return (
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.addButton}
            onClick={() => this.setState({openSidebar: !openSidebar})}
          >
            {openSidebar ? <icons.Close/> : <icons.FilterList/>}
          </IconButton>
          <Select
            className={classes.adminSelect}
            value={entitySource}
            input={<Input/>}
            onChange={event => {
              setSection('analyticsEntitySource', event.target.value);
              this.setState({
                entitySource: event.target.value,
                query: {
                  ...query,
                  address: '',
                },
              });
            }}
          >
            {entitySourceMenu.map((item, index) => (
              <MenuItem key={index} value={item[0]}>{item[1]}</MenuItem>
            ))}
          </Select>
          <AccessComponent managerOnly>
            <Select
              className={classes.adminSelect}
              value={match.params.source}
              input={<Input/>}
              onChange={event => {
                setSection('analyticsManagerSource', event.target.value);
                let newQuery = base64url.encode(JSON.stringify({
                  dateTo,
                  dateFrom,
                  updatedAtFrom,
                  updatedAtTo,
                  createdAtFrom,
                  createdAtTo,
                }));
                this.props.history.push(this.matchPath({...match.params, source: event.target.value}) + '?' + newQuery);
              }}
            >
              <MenuItem value="assigned">Моя аналитика</MenuItem>
              <MenuItem value="team" divider>Аналитика команды</MenuItem>
              {selfUser.teamMembers.map((member, index) => (
                <MenuItem key={index} value={member.id}>{member.name}</MenuItem>
              ))}
            </Select>
          </AccessComponent>
          <AccessComponent admin>
            <>
              <Select
                className={classes.adminSelect}
                value={currentTeam ? currentTeam : match.params.source}
                input={<Input/>}
                onChange={event => {
                  this.setState(prevState => ({
                    filter: {
                      ...prevState.filter,
                      source: event.target.value === 'all' ? 'all' : 'team',
                      team: event.target.value === 'all' ? null : event.target.value,
                    },
                  }));
                  setSection('analyticsAdminSource', event.target.value);
                  localStorage.removeItem('analyticsAdminAssignees');
                  this.handleTeamDropdown(event.target.value);
                }}
              >
                <MenuItem value="all">Общая аналитика</MenuItem>
                {selfUser.teams.map((team, index) => (
                  <MenuItem key={index} value={team.id}>{team.name}</MenuItem>
                ))}
              </Select>
              {this.state.members.length ? (
                <Select
                  className={classes.adminSelect}
                  value={match.params.source}
                  input={<Input/>}
                  onChange={event => {
                    this.handleMemberDropdown(event.target.value);
                    setSection('analyticsAdminAssignees', event.target.value);
                  }}
                >
                  <MenuItem value={currentTeam}>Аналитика команды</MenuItem>
                  {this.state.members.map((member, index) => (
                    <MenuItem key={index} value={member.id}>{member.name}</MenuItem>
                  ))}
                </Select>
              ) : null}
            </>
          </AccessComponent>
          {
            entitySource === 'appeals' || entitySource === 'request_offers' ? (
              <>
                <Hidden xsDown>
                  <div className={classes.flex}/>
                </Hidden>
                <InputBase
                  className={classes.input + ' ' + classes.searchInputWrapper}
                  placeholder="Введите адрес листинга для поиска"
                  value={searchAddress}
                  onChange={this.handleSearchAddressInput}
                />
              </>
            ) : null
          }
        </Toolbar>
        <Divider/>
        <div className={classes.wrapper}>
          <Sidebar
            filter={filter}
            open={openSidebar}
            chipAppeals={chipAppeals}
            entitySource={entitySource}
            initialValues={{
              dateFrom: dateFrom ? dateFrom : null,
              dateTo: dateTo ? dateTo : null,
              updatedAtFrom: updatedAtFrom || undefined,
              updatedAtTo: updatedAtTo || undefined,
              createdAtFrom: createdAtFrom || undefined,
              createdAtTo: createdAtTo || undefined,
            }}
            onSubmit={this.handleFilter}
          />
          <div className={classes.dashboardWrap}>
            {entitySource === 'deal' ? (
              <React.Fragment>
                <Query query={queryStatistic} variables={{filter}} fetchPolicy="network-only">
                  {({data, loading, error}) => {
                    if (loading) {
                      return (
                        <div className={classes.status}>
                          <CircularProgress size={24} thickness={5}/>
                        </div>
                      );
                    }

                    if (error) {
                      return (
                        <div className={classes.status}>
                          <icons.Warning color="primary"/>
                        </div>
                      );
                    }

                    const total = data.dealsStatistics.total;
                    return (
                      <>
                        <StatisticType
                          statistics={this.getData(data.dealsStatistics.statuses, dealStatuses, 'status')}
                          barchartTitle="Статистика по заявкам"
                          tableTitle="Статус"
                          total={total}
                          history={history}
                          match={match}
                          getHref={value => this.getOpenUrl('deal', 'statuses', value)}
                          handleClickOpen={event => this.handleClickOpen('deal', 'statuses', event)}
                          color={{colorStart: colors.pink[100], colorEnd: colors.pink[700]}}
                        />
                        <StatisticType
                          statistics={this.getData(data.dealsStatistics.offerTypes, offerTypes, 'type')}
                          barchartTitle="Статистика по виду сделки"
                          tableTitle="Вид сделки"
                          height={180}
                          total={total}
                          history={history}
                          match={match}
                          getHref={value => this.getOpenUrl('deal', 'offerType', value)}
                          handleClickOpen={event => this.handleClickOpen('deal', 'offerType', event)}
                          color={{colorStart: colors.indigo[100], colorEnd: colors.indigo[700]}}
                        />
                        <StatisticType
                          statistics={this.getData(data.dealsStatistics.classes, dealClasses, 'class', 'value')}
                          barchartTitle="Статистика по классу"
                          tableTitle="Класс"
                          height={180}
                          total={total}
                          history={history}
                          match={match}
                          getHref={value => this.getOpenUrl('deal', 'abcClass', value)}
                          handleClickOpen={event => this.handleClickOpen('deal', 'abcClass', event)}
                          color={{colorStart: colors.indigo[100], colorEnd: colors.indigo[700]}}
                        />
                        <StatisticType
                          statistics={this.getData(data.dealsStatistics.propertyTypes, propertyTypes, 'type')}
                          barchartTitle="Статистика по категории"
                          tableTitle="Категория"
                          height={500}
                          total={total}
                          history={history}
                          match={match}
                          getHref={value => this.getOpenUrl('deal', 'propertyTypes', value)}
                          handleClickOpen={event => this.handleClickOpen('deal', 'propertyTypes', event)}
                          color={{colorStart: colors.red[100], colorEnd: colors.red[700]}}
                        />
                        <AccessComponent manager>
                          <StatisticType
                            statistics={this.getData(data.dealsStatistics.sources, dealSources, 'source')}
                            barchartTitle="Статистика по источникам"
                            tableTitle="Источник"
                            height={670}
                            total={total}
                            history={history}
                            match={match}
                            getHref={value => this.getOpenUrl('deal', 'source', value)}
                            handleClickOpen={event => this.handleClickOpen('deal', 'source', event)}
                            color={{colorStart: colors.teal[100], colorEnd: colors.teal[700]}}
                          />
                        </AccessComponent>
                      </>
                    );
                  }}
                </Query>
                <Query query={queryDynamicStatics} variables={{filter: dynamicsFilter}} fetchPolicy="network-only">
                  {({data, loading, error}) => {
                    if (loading) {
                      return (
                        <div className={classes.status}>
                          <CircularProgress size={24} thickness={5}/>
                        </div>
                      );
                    }

                    if (error) {
                      return (
                        <div className={classes.status}>
                          <icons.Warning color="primary"/>
                        </div>
                      );
                    }

                    return (
                      <div className={classes.dynamicStatictics}>
                        <Typography
                          variant="subtitle1"
                          className={classes.statisticsTitle}
                        >Динамика заявок</Typography>
                        <div className={classes.monthPickerWrap}>
                          <DatePicker
                            views={['year', 'month']}
                            label="За месяц"
                            format="MMM yyyy"
                            value={dynamicsDate ? dynamicsDate : new Date()}
                            onChange={this.handleDynamicDateChange}
                          />
                        </div>
                        <Hidden xsDown>
                          <ContainerDimensions>
                            {({width}) =>
                              <BarChart
                                statistics={this.getDynamicData(data.dealsStatistics.dynamics)}
                                variant="vertical"
                                width={width}
                                height={400}
                                color={{colorStart: colors.cyan[100], colorEnd: colors.cyan[700]}}
                              />
                            }
                          </ContainerDimensions>
                        </Hidden>
                        <Hidden smUp>
                          <ContainerDimensions>
                            {({width}) =>
                              <BarChart
                                statistics={this.getDynamicData(data.dealsStatistics.dynamics)}
                                variant="horizontal"
                                width={width}
                                height={650}
                                marginLeft={80}
                                colorStart={colors.red[100]}
                                colorEnd={colors.red[500]}
                                color={{colorStart: colors.cyan[100], colorEnd: colors.cyan[700]}}
                              />
                            }
                          </ContainerDimensions>
                        </Hidden>
                        <DynamicsTable
                          statistics={this.getDynamicData(data.dealsStatistics.dynamics)}
                          total={data.dealsStatistics.total}
                          title="День"
                          getHref={date => this.getDynamicHref('deals', 'createdAt', date)}
                          handleClickOpen={date => this.handleDynamicsClick('deals', 'createdAt', date)}
                        />
                      </div>
                    );
                  }}
                </Query>
              </React.Fragment>
            ) : (
              entitySource === 'request' ? (
                <React.Fragment>
                  <Query query={queryRequestStatistic} variables={{filter}} fetchPolicy="network-only">
                    {({data, loading, error}) => {
                      if (loading) {
                        return (
                          <div className={classes.status}>
                            <CircularProgress size={24} thickness={5}/>
                          </div>
                        );
                      }

                      if (error) {
                        return (
                          <div className={classes.status}>
                            <icons.Warning color="primary"/>
                          </div>
                        );
                      }

                      const total = data.requestsStatistics.total;
                      const activitiesChartHeight = requestActivities.length ? requestActivities.length * 38 : 500;
                      return (
                        <>
                          <StatisticType
                            statistics={this.getData(data.requestsStatistics.offerTypes, offerTypes, 'type')}
                            barchartTitle="Статистика по виду сделки"
                            tableTitle="Вид сделки"
                            height={180}
                            total={total}
                            history={history}
                            match={match}
                            getHref={value => this.getOpenUrl('request', 'type', value)}
                            handleClickOpen={event => this.handleClickOpen('request', 'type', event)}
                            color={{colorStart: colors.indigo[100], colorEnd: colors.indigo[700]}}
                          />
                          <StatisticType
                            statistics={this.getData(data.requestsStatistics.activities, requestActivities, 'activity', 'name', 'id')}
                            barchartTitle="Статистика по сфере деятельности"
                            tableTitle="Сфера деятельности"
                            height={activitiesChartHeight}
                            total={total}
                            history={history}
                            match={match}
                            getHref={value => this.getOpenUrl('request', 'activities', value)}
                            handleClickOpen={event => this.handleClickOpen('request', 'activities', event)}
                            color={{colorStart: colors.red[100], colorEnd: colors.red[700]}}
                          />
                          <AccessComponent manager>
                            <StatisticType
                              statistics={this.getData(data.requestsStatistics.sources, dealSources, 'source')}
                              barchartTitle="Статистика по источникам"
                              tableTitle="Источник"
                              height={670}
                              total={total}
                              history={history}
                              match={match}
                              getHref={value => this.getOpenUrl('request', 'source', value)}
                              handleClickOpen={event => this.handleClickOpen('request', 'source', event)}
                              color={{colorStart: colors.teal[100], colorEnd: colors.teal[700]}}
                            />
                          </AccessComponent>
                        </>
                      );
                    }}
                  </Query>
                  <Query
                    query={queryRequestDynamicStatics}
                    variables={{filter: dynamicsFilter}}
                    fetchPolicy="network-only"
                  >
                    {({data, loading, error}) => {
                      if (loading) {
                        return (
                          <div className={classes.status}>
                            <CircularProgress size={24} thickness={5}/>
                          </div>
                        );
                      }

                      if (error) {
                        return (
                          <div className={classes.status}>
                            <icons.Warning color="primary"/>
                          </div>
                        );
                      }

                      return (
                        <div className={classes.dynamicStatictics}>
                          <Typography
                            variant="subtitle1"
                            className={classes.statisticsTitle}
                          >Динамика заявок</Typography>
                          <div className={classes.monthPickerWrap}>
                            <DatePicker
                              views={['year', 'month']}
                              label="За месяц"
                              format="MMM yyyy"
                              value={dynamicsDate ? dynamicsDate : new Date()}
                              onChange={this.handleDynamicDateChange}
                            />
                          </div>
                          <Hidden xsDown>
                            <ContainerDimensions>
                              {({width}) =>
                                <BarChart
                                  statistics={this.getDynamicData(data.requestsStatistics.dynamics)}
                                  variant="vertical"
                                  width={width}
                                  height={400}
                                  color={{colorStart: colors.cyan[100], colorEnd: colors.cyan[700]}}
                                />
                              }
                            </ContainerDimensions>
                          </Hidden>
                          <Hidden smUp>
                            <ContainerDimensions>
                              {({width}) =>
                                <BarChart
                                  statistics={this.getDynamicData(data.requestsStatistics.dynamics)}
                                  variant="horizontal"
                                  width={width}
                                  height={650}
                                  marginLeft={80}
                                  colorStart={colors.red[100]}
                                  colorEnd={colors.red[500]}
                                  color={{colorStart: colors.cyan[100], colorEnd: colors.cyan[700]}}
                                />
                              }
                            </ContainerDimensions>
                          </Hidden>
                          <DynamicsTable
                            statistics={this.getDynamicData(data.requestsStatistics.dynamics)}
                            total={data.requestsStatistics.total}
                            title="День"
                            getHref={date => this.getDynamicHref('requests', 'date', date)}
                            handleClickOpen={date => this.handleDynamicsClick('requests', 'date', date)}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </React.Fragment>
              ) : (
                entitySource === 'appeals' ? (
                  <React.Fragment>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Chip
                        className={classes.statisticChip}
                        label="Без обращений"
                        color={chipAppeals ? 'primary' : 'default'}
                        clickable={true}
                        onClick={() => this.setState({chipAppeals: !chipAppeals})}
                      />
                      <Select
                        className={classes.adminSelect}
                        value={typeAppeals}
                        input={<Input/>}
                        onChange={event => {
                          this.setState({typeAppeals: event.target.value});
                        }}
                      >
                        <MenuItem value="default">Тип сделки</MenuItem>
                        <MenuItem value="rent">Аренда</MenuItem>
                        <MenuItem value="sell">Продажа</MenuItem>
                      </Select>
                      <FormControlLabel
                        className={classes.platformManagementSwitch}
                        control={<Switch
                          color="primary"
                          checked={platformManagement}
                          onChange={(_, checked) => {
                            this.platformManagementSet(checked);
                          }}
                        />}
                        label="Управление площадками"
                        labelPlacement="start"
                      />
                    </div>
                    <Query
                      query={chipAppeals ? offersWithoutDealList : queryDealOfferStatistic}
                      variables={{filter}}
                      fetchPolicy="network-only"
                    >
                      {({data, loading, error}) => {
                        if (loading) {
                          return (
                            <div className={classes.status}>
                              <CircularProgress size={24} thickness={5}/>
                            </div>
                          );
                        }
                        if (error) {
                          return (
                            <div className={classes.status}>
                              <icons.Warning color="primary"/>
                            </div>
                          );
                        }
                        return (
                          chipAppeals
                            ? <OfferWithoutDealTable
                              statistics={data.offersWithoutDealList}
                              title="Листинги без входящих обращений"
                              titleClass={classes.statisticsTitle}
                              history={history}
                              match={match}
                              getHref={offerId => this.getOpenUrl('offer', 'id', offerId)}
                              handleClickOpen={offerId => this.handleClickOpen('offer', 'id', offerId)}
                              platformManagement={platformManagement}
                            />
                            : <DealOfferTable
                              statistics={data.dealOfferStatistics}
                              title="Статистика по входящим обращениям"
                              titleClass={classes.statisticsTitle}
                              history={history}
                              match={match}
                              getHref={offerId => this.getOpenUrl('offer', 'id', offerId)}
                              handleClickOpen={offerId => this.handleClickOpen('offer', 'id', offerId)}
                              platformManagement={platformManagement}
                            />
                        );
                      }}
                    </Query>
                  </React.Fragment>
                ) : (
                  entitySource === 'request_offers' ? (
                    <React.Fragment>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Chip
                          className={classes.statisticChip}
                          label="Без обращений"
                          color={chipAppeals ? 'primary' : 'default'}
                          clickable={true}
                          onClick={() => this.setState({chipAppeals: !chipAppeals})}
                        />
                        <Select
                          className={classes.adminSelect}
                          value={typeAppeals}
                          input={<Input/>}
                          onChange={event => {
                            this.setState({typeAppeals: event.target.value});
                          }}
                        >
                          <MenuItem value="default">Тип сделки</MenuItem>
                          <MenuItem value="rent">Аренда</MenuItem>
                          <MenuItem value="sell">Продажа</MenuItem>
                        </Select>
                        <FormControlLabel
                          className={classes.platformManagementSwitch}
                          control={<Switch
                            color="primary"
                            checked={platformManagement}
                            onChange={(_, checked) => {
                              this.platformManagementSet(checked);
                            }}
                          />}
                          label="Управление площадками"
                          labelPlacement="start"
                        />
                      </div>
                      <Query
                        query={chipAppeals ? queryRequestWithoutOffersStatistics : queryRequestOfferStatistic}
                        variables={{filter}}
                        fetchPolicy="network-only"
                      >
                        {({data, loading, error}) => {
                          if (loading) {
                            return (
                              <div className={classes.status}>
                                <CircularProgress size={24} thickness={5}/>
                              </div>
                            );
                          }
                          if (error) {
                            return (
                              <div className={classes.status}>
                                <icons.Warning color="primary"/>
                              </div>
                            );
                          }
                          return (
                            chipAppeals
                              ? <OfferWithoutDealTable
                                statistics={data.requestWithoutOffersStatistics}
                                title="Листинги без входящих обращений"
                                titleClass={classes.statisticsTitle}
                                history={history}
                                match={match}
                                getHref={offerId => this.getOpenUrl('offer', 'id', offerId)}
                                handleClickOpen={offerId => this.handleClickOpen('offer', 'id', offerId)}
                                platformManagement={platformManagement}
                              />
                              : <DealOfferTable
                                statistics={data.requestOfferStatistics}
                                title="Статистика по входящим обращениям"
                                titleClass={classes.statisticsTitle}
                                history={history}
                                match={match}
                                getHref={offerId => this.getOpenUrl('offer', 'id', offerId)}
                                handleClickOpen={offerId => this.handleClickOpen('offer', 'id', offerId)}
                                platformManagement={platformManagement}
                              />
                          );
                        }}
                      </Query>
                    </React.Fragment>
                  ) : null
                )
              ))}
          </div>
        </div>
      </div>
    );
  }

  handleFilter = dates => {
    if (dates) {
      const from = dates.dateFrom,
        to = dates.dateTo,
        inOffersSection = (this.state.entitySource === 'appeals' || this.state.entitySource === 'request_offers');
      let updatedAtFrom = undefined,
        updatedAtTo = undefined,
        createdAtFrom = undefined,
        createdAtTo = undefined,
        typeAppeals = undefined;

      if (inOffersSection) {
        typeAppeals = this.state.typeAppeals;
        if (this.state.chipAppeals) {
          updatedAtFrom = dates.updatedAtFrom;
          updatedAtTo = dates.updatedAtTo;
          createdAtFrom = dates.createdAtFrom;
          createdAtTo = dates.createdAtTo;
        }
      }

      const newQuery = {
        ...this.state.query,
        dateFrom: from,
        dateTo: to,
        updatedAtFrom,
        updatedAtTo,
        createdAtFrom,
        createdAtTo,
        typeAppeals,
      };
      const path = this.props.match.url + '?'
        + base64url.encode(JSON.stringify(newQuery));
      this.props.history.push(path);
    }
  };

  getDynamicHref = (entity, dateField, date) => {
    const {match, selfUser} = this.props;
    const {currentTeam, currentMember, startOfMonth, endOfMonth} = this.state;

    let queryDate = new Date(date);
    let filter = {};

    if (selfUser.role === 'ROLE_ADMIN') {
      filter = {
        source: 'ALL',
        filter: {
          [dateField + 'From']: date ? queryDate : startOfMonth,
          [dateField + 'To']: date ? queryDate : endOfMonth,
          team: match.params.source === currentTeam ? match.params.source : null,
          assignees: match.params.source === currentMember ? [match.params.source] : null,
        },
      };
    } else {
      filter = {
        source: match.params.source.length > 10 ? 'TEAM' : match.params.source.toUpperCase(),
        filter: {
          [dateField + 'From']: date ? queryDate : startOfMonth,
          [dateField + 'To']: date ? queryDate : endOfMonth,
          assignees: match.params.source.length > 10 ? [match.params.source] : null,
        },
      };
    }

    return `${match.url}/${entity}?${base64url.encode(JSON.stringify(filter))}`;
  };

  handleDynamicsClick = (entity, dateField, date) => {
    const {history} = this.props;
    history.push(this.getDynamicHref(entity, dateField, date));
  };

  getOpenUrl = (entity, field, value) => {
    const {match, selfUser} = this.props;

    if (entity === 'offer') {
      return '/offers/' + value;
    }

    const {currentTeam, currentMember, query} = this.state;
    if (entity === 'deal') {
      let filter = {};

      if (selfUser.role === 'ROLE_ADMIN') {
        filter = {
          source: 'ALL',
          filter: {
            [field]: field === 'statuses' || field === 'propertyTypes' || field === 'abcClass' ? [value] : value,
            createdAtFrom: query.dateFrom,
            createdAtTo: query.dateTo,
            team: match.params.source === currentTeam ? match.params.source : null,
            assignees: match.params.source === currentMember ? [match.params.source] : null,
          },
        };
      } else {
        filter = {
          source: match.params.source.length > 10 ? 'TEAM' : match.params.source.toUpperCase(),
          filter: {
            [field]: field === 'statuses' || field === 'propertyTypes' || field === 'abcClass' ? [value] : value,
            createdAtFrom: query.dateFrom,
            createdAtTo: query.dateTo,
            assignees: match.params.source.length > 10 ? [match.params.source] : null,
          },
        };
      }

      return `${match.url}/deals?${base64url.encode(JSON.stringify(filter))}`;
    } else if (entity === 'request') {
      let filter = {};

      if (selfUser.role === 'ROLE_ADMIN') {
        filter = {
          source: 'ALL',
          filter: {
            [field]: field === 'activities' ? [value] : value,
            dateFrom: query.dateFrom,
            dateTo: query.dateTo,
            team: match.params.source === currentTeam ? match.params.source : null,
            assignees: match.params.source === currentMember ? [match.params.source] : null,
          },
        };
      } else {
        filter = {
          source: match.params.source.length > 10 ? 'TEAM' : match.params.source.toUpperCase(),
          filter: {
            [field]: field === 'activities' ? [value] : value,
            dateFrom: query.dateFrom,
            dateTo: query.dateTo,
            assignees: match.params.source.length > 10 ? [match.params.source] : null,
          },
        };
      }

      return `${match.url}/requests?${base64url.encode(JSON.stringify(filter))}`;
    }

    return '';
  };

  handleClickOpen = (entity, field, value) => {
    const {history} = this.props;
    history.push(this.getOpenUrl(entity, field, value));
  };

  getData = (data, dataList, dataType, dataLabel, dataValue) => {
    let readyData = dataList.map(d => {
      let datum = find(data, {[dataType]: d[dataValue || 'value']});
      return datum ? {
        data: d[dataValue || 'value'],
        title: d[dataLabel || 'label'],
        value: datum.count,
        commission: datum.commission,
      } : {data: d[dataValue || 'value'], title: d[dataLabel || 'label'], value: 0, commission: 0};
    });
    return readyData;
  };

  getDynamicData = dynamics => {
    let readyData = this.state.monthDays.map(d => {
      let datum = find(dynamics, {date: format(d, 'yyyy-MM-dd')});
      return datum ? {
        data: format(d, 'yyyy-MM-dd'),
        title: format(d, 'dd'),
        value: datum.count,
      } : {data: format(d, 'yyyy-MM-dd'), title: format(d, 'dd'), value: 0};
    });
    return readyData;
  };

  handleDynamicDateChange = date => {
    const newQuery = {...this.state.query, dynamicsDate: date};
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify(newQuery));
    this.props.history.push(path);

    this.handleDynamic(date);
  };

  handleDynamic = date => {
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);
    this.setState({
      startOfMonth: startDate,
      endOfMonth: endDate,
      monthDays: eachDayOfInterval({start: startDate, end: endDate}),
    });
  };

  handleTeamDropdown = id => {
    const {match} = this.props;
    const {query} = this.state;
    const {
      dateFrom,
      dateTo,
      updatedAtFrom,
      updatedAtTo,
      createdAtFrom,
      createdAtTo,
    } = query;
    this.setState({currentTeam: id === 'all' ? null : id});
    if (id !== 'all') {
      this.getTeamMembers(id);
    } else {
      this.setState({members: []});
    }
    let newQuery = base64url.encode(JSON.stringify({
      dateTo,
      dateFrom,
      updatedAtFrom,
      updatedAtTo,
      createdAtFrom,
      createdAtTo,
    }));
    this.props.history.push(this.matchPath({...match.params, source: id}) + '?' + newQuery);
  };

  handleMemberDropdown = id => {
    const {match} = this.props;
    const {currentTeam, query} = this.state;
    this.setState({currentMember: id === currentTeam ? null : id});
    const {
      dateFrom,
      dateTo,
      updatedAtFrom,
      updatedAtTo,
      createdAtFrom,
      createdAtTo,
    } = query;
    let newQuery = base64url.encode(JSON.stringify({
      dateTo,
      dateFrom,
      updatedAtFrom,
      updatedAtTo,
      createdAtFrom,
      createdAtTo,
    }));
    this.props.history.push(this.matchPath({...match.params, source: id}) + '?' + newQuery);
  };

  getTeamMembers = id => {
    axios.get(`/api/teams/${id}/members`)
      .then(res => {
        this.setState({members: res.data.items});
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  };

  findMember = id => {
    const {selfUser} = this.props;
    axios.get(`/api/members/${id}`)
      .then(res => {
        const {team} = res.data;
        this.getTeamMembers(team);
        this.setState({currentTeam: team, currentMember: id});
      })
      .catch(() => {
        if (selfUser && find(selfUser.teams, team => team.id === id)) {
          this.setState({currentTeam: id});
          this.getTeamMembers(id);
        } else {
          alert('Произошла ошибка');
          this.props.history.push(this.matchPath({...this.props.match.params, source: 'all'}));
        }
      });
  };

  loadStaticstics = () => {
    const {selfUser, match} = this.props;

    if (selfUser.role === 'ROLE_ADMIN') {
      if (match.params.source === 'assigned' || match.params.source === 'team' || match.params.source === 'all') {
        let source = getSection('analyticsAdminSource');
        let assignees = getSection('analyticsAdminAssignees');
        if (assignees) {
          this.findMember(assignees);
          this.props.history.push(this.matchPath({...match.params, source: assignees || 'all'}));
        } else {
          if (source && source.length > 10) {
            this.findMember(source);
            this.props.history.push(this.matchPath({...match.params, source: source || 'all'}));
          } else
            this.props.history.push(this.matchPath({...match.params, source: source || 'all'}));
        }
      } else {
        if (match.params.source.length > 10)
          this.findMember(match.params.source);
      }
    }

    if ((selfUser.role === 'ROLE_TEAM_MANAGER') && ((match.params.source.length > 10 && !find(selfUser.teamMembers, member => member.id === match.params.source)) || match.params.source === 'all' || match.params.source === 'assigned')) {
      let source = getSection('analyticsManagerSource');
      this.props.history.push(this.matchPath({...match.params, source: source || 'assigned'}));
    }
    if ((selfUser.role === 'ROLE_TEAM_MEMBER' || selfUser.role === 'ROLE_BROKER') && (match.params.source !== 'assigned')) {
      this.props.history.push(this.matchPath({...match.params, source: 'assigned'}));
    }
  };
}

const styles = theme => ({
  flex: {
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    flex: 1,
  },
  addButton: {
    marginLeft: -12,
    marginRight: 12,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  root: {
    flex: 1,
    backgroundColor: colors.grey[200],
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  dashboardWrap: {
    flex: 1,
    overflowY: 'auto',
    padding: '20px 0',
  },
  monthPickerWrap: {
    display: 'flex',
    justifyContent: 'end',
    padding: '0 20px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
  },
  status: {
    margin: 8,
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
  },
  adminSelect: {
    marginRight: 12,
    [theme.breakpoints.down(500)]: {
      width: '100%',
    },
  },
  toolbar: {
    [theme.breakpoints.down(500)]: {
      flexWrap: 'wrap',
      paddingBottom: 10,
    },
  },
  statisticsTitle: {
    textAlign: 'center',
    marginBottom: 10,
  },
  statisticChip: {
    margin: '0 20px',
  },
  searchInputWrapper: {
    backgroundColor: colors.grey[300],
    padding: '5px 10px',
    borderRadius: 5,
    width: 300,
    maxWidth: '100%',
  },
  platformManagementSwitch: {
    marginLeft: 'auto',
    marginRight: 16,
  },
});

AnalyticsPage = connect(
  state => ({
    selfUser: state.root.selfUser,
    dealStatuses: state.root.classification.dealStatuses,
    dealSources: state.root.classification.dealSources,
    offerTypes: state.root.classification.offerTypes,
    propertyTypes: state.root.classification.propertyTypes,
    dealClasses: state.root.classification.classificationDeal,
    requestActivities: state.root.classification.activities,
  })
)(AnalyticsPage);

export default withApollo(withStyles(styles)(AnalyticsPage));
