import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {Paper} from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import {setTitle} from '../../actions';
import PersonDetails from './PersonDetails';
import DeviceList from './DeviceList';
import AccessComponent from '../../components/AccessComponent';
import TelegramNotifications from './TelegramNotifications';

class Person extends Component {

  state = {
    member: null,
    telegramAuth: false,
    telegramAuthError: null,
  };

  componentDidMount() {
    this.props.setTitle('Профиль');
    const {selfUser} = this.props;
    this.loadMember(selfUser);
  }

  loadMember = (currentUser, id) => {
    if(!currentUser) return;

    const {match} = this.props;

    if(currentUser.id === (id || match.params.memberId)){
      this.props.setTitle('Личный кабинет');
      this.setState({
        member: currentUser,
      });
    }
    else{
      axios.get(`/api/members/${match.params.memberId}`)
        .then(resp => {
          this.setState({
            member: resp.data,
          });
        });
    }
  };

  componentWillReceiveProps = newProps => {
    if(newProps.selfUser && this.props.selfUser && newProps.selfUser.id !== this.props.selfUser.id)
      this.loadMember(newProps.selfUser);
    if(newProps.match.params.memberId !== this.props.match.params.memberId && newProps.selfUser)
      this.loadMember(newProps.selfUser, newProps.match.params.memberId);
  };

  render() {
    const {member, telegramAuthError} = this.state;

    if(!member)
      return null;
    return (
      <React.Fragment>
        <div style={{flexDirection: 'row', flex: 1, display: 'flex'}}>
          <Hidden xsDown>
            <div style={{flex: 1, backgroundColor: colors.grey[200]}}/>
          </Hidden>
          <div style={{flex: 2, paddingLeft: 10, paddingRight: 10}}>
            <Paper elevation={0} square className="column" style={{height: '100%'}}>
              <CardHeader
                title={member.name}
                titleTypographyProps={{
                  style : {
                    fontSize: '1.3em',
                  }}
                }
                avatar={
                  <Avatar style={{height: 75, width: 75}}>
                    <icons.Person style={{fontSize: 50}}/>
                  </Avatar>
                }
              />
              <PersonDetails
                member={member}
                telegramAuthError={telegramAuthError}
                onTelegramAuthChanged={enabled => this.setState({
                  telegramAuthError: null,
                  member: {
                    ...member,
                    telegramAuth: enabled,
                  },
                })}
                onTelegramAuthError={error => this.setState({telegramAuthError: error})}
              />
              <AccessComponent condition={member.id === this.props.selfUser.id}>
                <TelegramNotifications/>
                <DeviceList/>
              </AccessComponent>
            </Paper>
          </div>
          <Hidden xsDown>
            <div style={{flex: 1, backgroundColor: colors.grey[200]}}/>
          </Hidden>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  selfUser: state.root.selfUser,
}), {setTitle})(Person);
