import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {connect} from 'react-redux';
import {copyToClipboard, copyToClipboardFeedback, formatDate, mapLabels, showResponseError} from '../../utils';
import {colors} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {reduxForm} from 'redux-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import SnackbarError from '../../components/SnackbarError';
import SnackbarSuccess from '../../components/SnackbarSuccess';
import {changeAvitoProlongation} from '../../actions';
import * as userRoles from '../../constants/userRoles';
import {hasRole} from '../../utils/roleFunc';
import {showModal} from '../../components/ModalManager';

class OfferAvitoReport extends Component {
  state = {
    avitoIdCopied: false,
    offerIdCopied: false,
    report: {},
    successMessage: null,
    errorMessage: null,
  };

  componentDidMount() {
    if (this.props.offer && this.props.offer.avitoReport) {
      this.setState({report: this.props.offer.avitoReport});
    }
  }

  getTimerLabel(timer) {
    if (!timer) {
      return 'Не выгружается';
    }

    if (typeof timer === 'string') {
      return formatDate(timer);
    }

    return 'На паузе';
  }

  changeExportAvitoId(offerId) {
    axios
      .post('/api/v1/changeOfferExportAvitoId', {offerId})
      .then(res => {
        if (res.data && res.data.id) {
          this.setState({
            report: {
              ...this.state.report,
              offerId: res.data.id,
            },
          });
          showModal(`XML ID изменён на ${res.data.id}`);
          return;
        }
        showResponseError();
      })
      .catch(error => {
        showResponseError(error.response);
      });
  }

  changeProlongation(offerId, value, once) {
    const {changeAvitoProlongation} = this.props;
    changeAvitoProlongation(offerId, value, once)
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({successMessage: 'Настройка сохранена'});
          return;
        }
        this.setState({errorMessage: 'Не удалось сохранить настройку'});
      })
      .catch(() => {
        this.setState({errorMessage: 'Не удалось сохранить настройку'});
      });
  }

  render() {
    const {
      classes,
      onClose,
      open,
      offer,
      selfUser,
      avitoAdStatuses,
    } = this.props;
    const {
      offerIdCopied,
      avitoIdCopied,
      report,
      successMessage,
      errorMessage,
    } = this.state;

    const editableProlongation = hasRole(selfUser.role, userRoles.MANAGER) || hasRole(selfUser.role, userRoles.ADMIN);

    return (
      <Dialog open={open} classes={{paper: classes.dialog}}>
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.dialogTitleWrap}>
            <div className={classes.dialogText}>
              <Typography variant="h6">Отчёт Авито</Typography>
            </div>
            <div className={classes.dialogBtn}>
              <IconButton
                onClick={() => onClose()}
                className={classes.dialogIcon}
              >
                <icons.Close/>
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Divider/>
        {report ? (
          <div className={classes.report}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <icons.Apartment/>
                </ListItemIcon>
                <ListItemText
                  inset
                  primary="Статус"
                  secondary={avitoAdStatuses[report.status] || 'Статус не определён'}
                />
              </ListItem>
              <Divider/>
              {
                report.timer ? (
                  <>
                    <ListItem>
                      <ListItemIcon>
                        <icons.Event style={{color: colors.blue[500]}}/>
                      </ListItemIcon>
                      <ListItemText inset primary="Дата конца публикации Авито" secondary={formatDate(report.timer)}/>
                    </ListItem>
                    <Divider/>
                  </>
                ) : null
              }
              <ListItem>
                <ListItemIcon>
                  <icons.AvTimer style={{color: colors.blueGrey[500]}}/>
                </ListItemIcon>
                <ListItemText
                  inset
                  primary="Таймер публикации CRM"
                  secondary={this.getTimerLabel(offer.exportAvitoTimer)}
                />
              </ListItem>
              <Divider/>
              {
                report.id ? (
                  <>
                    <ListItem
                      button
                      onClick={
                        e => {
                          copyToClipboard(report.id, e.target).then(() => {
                            copyToClipboardFeedback(
                              () => this.setState({avitoIdCopied: true}),
                              () => this.setState({avitoIdCopied: false})
                            );
                          });
                        }
                      }
                    >
                      <Tooltip placement="top" open={avitoIdCopied} title="Скопировано">
                        <ListItemIcon>
                          <icons.Assignment/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText inset primary={'Avito ID: ' + report.id} secondary="Нажмите, чтобы скопировать"/>
                    </ListItem>
                    <Divider/>
                  </>
                ) : null
              }
              {
                report.offerId ? (
                  <>
                    <ListItem
                      button
                      onClick={
                        e => {
                          copyToClipboard(report.offerId, e.target).then(() => {
                            copyToClipboardFeedback(
                              () => this.setState({offerIdCopied: true}),
                              () => this.setState({offerIdCopied: false})
                            );
                          });
                        }
                      }
                    >
                      <Tooltip placement="top" open={offerIdCopied} title="Скопировано">
                        <ListItemIcon>
                          <icons.Assignment/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText inset primary={'XML ID: ' + report.offerId} secondary="Нажмите, чтобы скопировать"/>
                    </ListItem>
                    <Divider/>
                  </>
                ) : null
              }
              {
                ['old', 'archived', 'removed'].includes(report.status) ? (
                  <>
                    <ListItem
                      button
                      onClick={() => this.changeExportAvitoId(offer.id)}
                    >
                      <ListItemIcon>
                        <icons.Cached/>
                      </ListItemIcon>
                      <ListItemText
                        inset
                        primary={'Создать новое XML ID для объявления'}
                        secondary="Нажмите, чтобы изменить ID"
                      />
                    </ListItem>
                    <Divider/>
                  </>
                ) : null
              }
              {
                report.url ? (
                  <>
                    <ListItem
                      dense
                      button
                      component="a"
                      href={report.url}
                      target="_blank"
                    >
                      <ListItemIcon>
                        <icons.Link/>
                      </ListItemIcon>
                      <ListItemText inset primary="Перейти к объявлению на Авито"/>
                    </ListItem>
                    <Divider/>
                  </>
                ) : null
              }
              <>
                <ListItem style={{paddingBottom: offer.avitoProlongation ? 0 : 11}}>
                  <FormControlLabel
                    control={<Switch
                      color="primary"
                      style={{color: colors.blue[500]}}
                      className={classes.prolongationSwitch}
                      checked={offer.avitoProlongation}
                      onChange={(_, checked) => {
                        this.changeProlongation(offer.id, checked, false);
                      }}
                      disabled={!editableProlongation}
                    />}
                    label={<Typography className={classes.prolongationLabel}>Автопродление</Typography>}
                  />
                </ListItem>
                {
                  offer.avitoProlongation ? (
                    <ListItem style={{paddingTop: 0}}>
                      <FormControl margin="none" style={{marginLeft: 56}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              style={{color: colors.green[500]}}
                              checked={offer.avitoProlongationOnce}
                              onClick={() => {
                                this.changeProlongation(offer.id, true, !offer.avitoProlongationOnce);
                              }}
                              disabled={!editableProlongation}
                            />
                          }
                          label={<Typography className={classes.prolongationOnceLabel}>Единоразовое</Typography>}
                        />
                        <Typography className={classes.prolongationOnceDesc}>
                          Автопродление будет отключено при использовании
                        </Typography>
                      </FormControl>
                    </ListItem>
                  ) : null
                }
                <Divider/>
              </>
            </List>
            <Typography variant="subtitle1" className={classes.notesTitle}>
              Уведомления
            </Typography>
            <List>
              {
                report.messages && report.messages.length > 0 ? (
                  report.messages.map((message, i) => {
                    return (
                      <div key={i}>
                        <ListItem>
                          {
                            message.type === 'info' ? (
                              <ListItemIcon>
                                <icons.Info style={{color: colors.blue[400]}}/>
                              </ListItemIcon>
                            ) : null
                          }
                          {
                            message.type === 'error' ? (
                              <ListItemIcon style={{color: colors.red[500]}}>
                                <icons.Error/>
                              </ListItemIcon>
                            ) : null
                          }
                          {
                            message.type === 'warning' || message.type === 'alarm' ? (
                              <ListItemIcon style={{color: colors.yellow[800]}}>
                                <icons.Warning/>
                              </ListItemIcon>
                            ) : null
                          }
                          <ListItemText
                            primary={message.title || ''}
                            primaryTypographyProps={{color: 'secondary'}}
                            secondary={
                              <>
                                <Typography component="span">{message.description || ''}</Typography>
                                {message.updated_at ? formatDate(message.updated_at) || '' : null}
                              </>
                            }/>
                        </ListItem>
                        <Divider/>
                      </div>
                    );
                  })
                ) : null
              }
            </List>
          </div>
        ) : null}
        <SnackbarError
          open={!!errorMessage}
          onClose={() => this.setState({errorMessage: null})}
          errorMessage={errorMessage}
        />
        <SnackbarSuccess
          open={!!successMessage}
          message={successMessage}
          onClose={() => this.setState({successMessage: null})}
        />
      </Dialog>
    );
  }
}

const styles = () => ({
  dialog: {
    display: 'flex',
    position: 'relative',
    overflowY: 'auto',
    flexDirection: 'column',
    margin: 0,
  },
  dialogIcon: {
    marginLeft: 15,
  },
  dialogTitleWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 0,
    minHeight: 150,
  },
  dialogTitle: {
    padding: '12px 24px 8px 24px',
  },
  dialogBtn: {
    marginRight: -12,
  },
  flexWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  report: {
    minWidth: 400,
  },
  notesTitle: {
    textAlign: 'center',
    margin: '20px 10px auto',
  },
  prolongationSwitch: {
    marginRight: 10,
  },
  prolongationLabel: {
    fontSize: '1rem',
    userSelect: 'none',
  },
  prolongationOnceLabel: {
    userSelect: 'none',
  },
  prolongationOnceDesc: {
    fontSize: '0.75rem',
    color: colors.grey[500],
  },
});

OfferAvitoReport = connect(
  state => ({
    selfUser: state.root.selfUser,
    avitoAdStatuses: mapLabels(state.root.classification.avitoAdStatuses, 'value', 'label'),
  }),
  {
    changeAvitoProlongation,
  },
)(OfferAvitoReport);

OfferAvitoReport = reduxForm({
  form: 'offer_avito_report',
  enableReinitialize: true,
})(OfferAvitoReport);

export default withStyles(styles)(OfferAvitoReport);
