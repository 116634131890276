import React, {Component} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';
import * as userRoles from '../../constants/userRoles';
import {
  openLightbox,
  setPressReleaseCloseDealArchived,
  setPressReleaseCloseDealPublished,
  setPressReleaseEventArchived,
  setPressReleaseEventPublished,
} from '../../actions';
import {formatDateWithoutTime, getImageUrl, ImageFilters, mapLabels} from '../../utils';
import {hasRole} from '../../utils/roleFunc';

class PropertyDetails extends Component {
  state = {
    district: null,
    subDistrict: null,
    anchorEl: null,
  };

  componentDidMount() {
    const {pressRelease} = this.props;
    this.setState({
      published: pressRelease.published ? 'Опубликован' : 'Не опубликован',
      archived: pressRelease.archived ? 'Архивирован' : 'Не архивирован',
    });
  }

  getPropertyTypeLabel = propertyType => {
    const {propertyTypes} = this.props;
    let type = propertyTypes.find(item => item.value === propertyType);
    return type ? type.label : '';
  };


  handleItemPublisherClick = flag => {
    const {
      id,
      type,
      setPressReleaseEventPublished,
      setPressReleaseCloseDealPublished,
      pressRelease,
    } = this.props;
    if (flag === pressRelease.published)
      return;
    let url = '/api/v1/pressRelease/setEventPublished',
      method = setPressReleaseEventPublished;
    if (type === 'dealClose') {
      url = '/api/v1/pressRelease/setClosedDealPublished';
      method = setPressReleaseCloseDealPublished;
    }
    axios
      .post(url, {id, published: flag})
      .then(() => {
        method(id, flag);
      })
      .catch(error => {
        if (error.response.status === 403) {
          alert('Не достаточно прав');
        }
      });
    this.setState({anchorElPublished: null});
  };

  handleItemArchivedClick = flag => {
    const {
      id,
      type,
      setPressReleaseEventArchived,
      setPressReleaseCloseDealArchived,
      user,
      history,
      pressRelease,
    } = this.props;
    this.setState({anchorElArchived: null});
    if (flag === pressRelease.archived)
      return;
    let url = '/api/v1/pressRelease/setEventArchived',
      method = setPressReleaseEventArchived;
    if (type === 'dealClose') {
      url = '/api/v1/pressRelease/setClosedDealArchived';
      method = setPressReleaseCloseDealArchived;
    }
    axios
      .post(url, {id, archived: flag})
      .then(() => {
        method(id, flag);
        if (hasRole(user.role, userRoles.MEMBER) || hasRole(user.role, userRoles.BROKER))
          history.push('/pressRelease');
      })
      .catch(error => {
        if (error.response.status === 403) {
          alert('Не достаточно прав');
        }
      });
  };

  render() {
    const {
      classes,
      openLightbox,
      pressRelease,
      pressReleaseDealTypes,
      pressReleaseBuyerServices,
      pressReleaseContractTypes,
      pressReleaseOwnerServices,
      pressReleaseFeatures,
      type,
    } = this.props;
    const {anchorElPublished, anchorElArchived} = this.state;
    return (
      <div className="content" style={{background: 'white'}}>
        <List>
          <ListItem
            dense
            button
            className={classes.statusItem}
            onClick={event => this.setState({anchorElPublished: event.currentTarget})}
          >
            <ListItemText
              primary={pressRelease.published ? 'Да' : 'Нет'}
              secondary={'Публикация на сайте'}
            />
            <icons.Edit className={classes.editIcon}/>
          </ListItem>
          <Menu
            open={Boolean(anchorElPublished)}
            anchorEl={anchorElPublished}
            onClose={() => this.setState({anchorElPublished: null})}
            classes={{paper: classes.menuPaper}}
          >
            <MenuItem
              value={false}
              onClick={() => this.handleItemPublisherClick(false)}
            >
              Нет
            </MenuItem>
            <MenuItem
              value={true}
              onClick={() => this.handleItemPublisherClick(true)}
            >
              Да
            </MenuItem>
          </Menu>
          <ListItem
            dense
            button
            className={classes.statusItem}
            onClick={event => this.setState({anchorElArchived: event.currentTarget})}
          >
            <ListItemText
              primary={pressRelease.archived ? 'Да' : 'Нет'}
              secondary={'В архиве'}
            />
            <icons.Edit className={classes.editIcon}/>
          </ListItem>
          <Menu
            open={Boolean(anchorElArchived)}
            anchorEl={anchorElArchived}
            onClose={() => this.setState({anchorElArchived: null})}
            classes={{paper: classes.menuPaper}}
          >
            <MenuItem
              onClick={() => this.handleItemArchivedClick(false)}
            >
              Нет
            </MenuItem>
            <MenuItem
              onClick={() => this.handleItemArchivedClick(true)}
            >
              Да
            </MenuItem>
          </Menu>
          {pressRelease.youTubeUrl ? (
            <ListItem
              className={classes.externalLinkListItem}
              dense
              button
              component="a"
              href={pressRelease.youTubeUrl}
              target="_blank"
            >
              <ListItemText primary="YouTube" secondary="Открыть видео"/>
              <icons.Link className={classes.externalLinkIcon}/>
            </ListItem>
          ) : null}
          {
            type === 'dealClose' || type === 'news' ? (
              <ListItem dense>
                <ListItemText
                  primary={pressRelease.federal ? 'Да' : 'Нет'}
                  secondary="Федеральный уровень"
                />
              </ListItem>
            ) : null
          }
          {pressRelease.dealType ?
            <ListItem dense>
              <ListItemText
                primary={pressReleaseDealTypes.find(dealType => pressRelease.dealType === dealType.value).label}
                secondary="Вид сделки"
              />
            </ListItem>
            : null}
          {pressRelease.rentPeriod ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.rentPeriod}
                secondary="Срок сдачи"
              />
            </ListItem>
            : null}
          {pressRelease.idlePeriod ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.idlePeriod}
                secondary="Простой объекта"
              />
            </ListItem>
            : null}
          {pressRelease.propertyType ?
            <ListItem dense>
              <ListItemText
                primary={this.getPropertyTypeLabel(pressRelease.propertyType)}
                secondary="Что реализовано"
              />
            </ListItem>
            : null}
          {pressRelease.propertyAddress ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.propertyAddress}
                secondary="Адрес объекта"
              />
            </ListItem>
            : null}
          {pressRelease.propertyArea ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.propertyArea}
                secondary="Площадь объекта"
              />
            </ListItem>
            : null}
          {pressRelease.buyer ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.buyer}
                secondary="Арендатор/Покупатель"
              />
            </ListItem>
            : null}
          {pressRelease.activityType ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.activityType}
                secondary="Вид деятельности/бизнеса клиента"
              />
            </ListItem>
            : null}
          {pressRelease.requirements ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.requirements}
                secondary="Требования клиента"
              />
            </ListItem>
            : null}
          {pressRelease.buyerServices ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.buyerServices.map(item => pressReleaseBuyerServices[item]).join(', ')}
                secondary="Услуги оказанные арендатору/покупателю"
              />
            </ListItem>
            : null}
          {pressRelease.contractType ?
            <ListItem dense>
              <ListItemText
                primary={pressReleaseContractTypes.find(item => item.value === pressRelease.contractType).label}
                secondary="Работа с собственником по договору"
              />
            </ListItem>
            : null}
          {pressRelease.ownerServices ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.ownerServices.map(item => pressReleaseOwnerServices[item]).join(', ')}
                secondary="Услуги оказанные собственнику"
              />
            </ListItem>
            : null}
          {pressRelease.workInProgress ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.workInProgress === 'y' ? 'Да' : 'Нет'}
                secondary="Продолжена ли работа с собственником по другим объектам"
              />
            </ListItem>
            : null}
          {pressRelease.features ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.features.map(item => pressReleaseFeatures[item]).join(', ')}
                secondary="Особенности сделки"
              />
            </ListItem>
            : null}
          {pressRelease.date ?
            <ListItem dense>
              <ListItemText
                primary={`${formatDateWithoutTime(pressRelease.date)}`}
                secondary={type === 'videos' ? 'Дата публикации' : 'Дата события'}
              />
            </ListItem>
            : null}
          {pressRelease.members ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.members}
                secondary="Участники"
              />
            </ListItem>
            : null}
          {type !== 'articles' && type !== 'news' && pressRelease.description ?
            <ListItem dense>
              <ListItemText
                primary={pressRelease.description}
                secondary="Описание"
                style={{whiteSpace: 'break-spaces'}}
              />
            </ListItem>
            : null}
        </List>
        {pressRelease.photos && pressRelease.photos.length > 0 ? (
          <div className={classes.photos}>
            <Typography variant="caption" gutterBottom>Фотография</Typography>
            <GridList cellHeight={300} spacing={4}>
              {pressRelease.photos.map((photo, i) => (
                <GridListTile key={i} onClick={() => openLightbox(pressRelease.photos, i)}>
                  <img src={getImageUrl({filter: ImageFilters.crop, width: 800, height: 600, path: photo})} alt=""/>
                </GridListTile>
              ))}
            </GridList>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = theme => ({
  avatar: {
    background: colors.blue[300],
  },
  photos: theme.mixins.gutters({
    paddingTop: 8,
    paddingBottom: 8,
  }),
  editIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',
  },
  menuPaper: {
    minWidth: 288,
  },
  headerPopoverPaper: {
    padding: 16,
    width: '100%',
  },
  statusItem: {
    cursor: 'pointer',
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
});

export default connect(
  ({root}) => ({
    propertyStatusLabels: mapLabels(root.classification.propertyStatuses, 'value', 'label'),
    propertyTypeLabels: mapLabels(root.classification.propertyTypes, 'value', 'label'),
    propertyStatuses: root.classification.propertyStatuses,
    propertyPurposeLabels: mapLabels(root.classification.propertyPurposes, 'value', 'label'),
    propertyTypes: root.classification.propertyTypes,
    pressReleaseDealTypes: root.classification.pressReleaseDealTypes,
    pressReleaseBuyerServices: mapLabels(root.classification.pressReleaseBuyerServices, 'value', 'label'),
    pressReleaseContractTypes: root.classification.pressReleaseContractTypes,
    pressReleaseOwnerServices: mapLabels(root.classification.pressReleaseOwnerServices, 'value', 'label'),
    pressReleaseFeatures: mapLabels(root.classification.pressReleaseFeatures, 'value', 'label'),
    user: root.selfUser,
  }),
  {
    openLightbox,
    setPressReleaseCloseDealPublished,
    setPressReleaseEventPublished,
    setPressReleaseEventArchived,
    setPressReleaseCloseDealArchived,
  }
)(withStyles(styles)(PropertyDetails));
